const alphaNumericAccountNumberUtilities = ['DUKE', 'DPL']

export const mapAccountNumber = (accountNumber?: string, utilityCode?: string) => {
    if (accountNumber && utilityCode && !alphaNumericAccountNumberUtilities.includes(utilityCode)) {
        return accountNumber.replace(/[^\d?]/g, '')
    }
    return accountNumber
}

const alphaNumericAccountNumberUtilityCodes = ['NIPSCO', 'CHD', 'DPL']

export const isSpecialCaseAccountNumberMask = (utilityCode: string) => {
    if (alphaNumericAccountNumberUtilityCodes.includes(utilityCode)) {
        return true
    }
    return false
}

export const getDPLTempAccountNumberRegex = () => {
    return '^2\\d{11}Z\\d{10}$'
}

export const stripQuestionMarksFromAccountNumberMask = (accountNumberMask: string) => {
    return accountNumberMask.replace(/\?/g, '')
}
