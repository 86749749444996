import * as React from 'react'
import { useFormContext } from 'react-hook-form'

import { get } from 'lodash-es'
import styled from 'styled-components'
import { withHookFormMask } from 'use-mask-input'

import { OfferAccountType } from '@igs-web/common-models/constants/account-type'
import { PhoneType, phoneTypes } from '@igs-web/common-models/constants/phone'
import { ContactModel } from '@igs-web/common-models/models/contact-model'
import { Input, LegalCheckBox, Select, SelectOption } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { Layout2fr1frColumn } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { emailRegex } from '@igs-web/common-utilities/utilities/form-validation'
import { allSameDigitPhoneNumber, formattedPhoneNumberRegex, isPhoneNumber, parsePhoneNumber } from '@igs-web/common-utilities/utilities/phone-utilities'

import { useCompany } from '../../company/hooks/useCompany'
import { EmailMarketingCheckbox } from '../fields/email-marketing-checkbox'

const requiredMsg = 'Required'
const SpanInput = styled(Input)`
    grid-column: 1/ -1;
`
const SpanColumn = styled.div`
    grid-column: 1/ -1;
`
export const CustomerContactForm = ({ formKey, idPrefix, offerAccountType, contact, showMarketingCheckBox }: CustomerContactFormProps) => {
    const {
        register,
        formState: { errors },
        watch,
        setValue,
        getValues,
    } = useFormContext()

    const { enrollment } = useCompany()

    const isCommercial = offerAccountType === OfferAccountType.Commercial

    const commercialNameField = `${formKey}commercialName`
    const commercialNameFieldErrors = get(errors, `${commercialNameField}`)?.message?.toString()

    const firstNameField = `${formKey}firstName`
    const firstNameFieldErrors = get(errors, `${firstNameField}`)?.message?.toString()

    const middleInitialField = `${formKey}middleInitial`

    const lastNameField = `${formKey}lastName`
    const lastNameFieldErrors = get(errors, `${lastNameField}`)?.message?.toString()

    const phoneNumberField = `${formKey}phoneNumber`
    const phoneNumberFieldErrors = get(errors, `${phoneNumberField}`)?.message?.toString()

    const alternatePhoneNumberField = `${formKey}alternatePhoneNumber`
    const alternatePhoneNumberFieldErrors = get(errors, `${alternatePhoneNumberField}`)?.message?.toString()

    const phoneTypeField = `${formKey}phoneType`
    const phoneTypeFieldErrors = get(errors, `${phoneTypeField}`)?.message?.toString()

    const alternatePhoneTypeField = `${formKey}alternatePhoneType`
    const alternatePhoneTypeFieldErrors = get(errors, `${alternatePhoneTypeField}`)?.message?.toString()

    const isMobilePhoneMarketableField = `${formKey}isMobilePhoneMarketable`

    const isMobilePhoneField = `${formKey}isMobilePhone`

    const emailField = `${formKey}emailAddress`
    const emailFieldErrors = get(errors, `${emailField}`)?.message?.toString()

    const phoneType = watch(phoneTypeField)
    const isEmailFieldLocked = !!getValues(emailField)

    const determineMobilePhoneType = () => {
        if (phoneType === PhoneType.Cell) {
            return true
        } else if (!phoneType && contact.phoneType === PhoneType.Cell) {
            return true
        } else {
            return false
        }
    }
    const isMobilePhone = determineMobilePhoneType()

    register(isMobilePhoneField, { shouldUnregister: true })

    React.useEffect(() => {
        setValue(isMobilePhoneField, isMobilePhone)
    }, [phoneType, setValue])

    return (
        <Layout2fr1frColumn>
            {isCommercial && (
                <SpanInput
                    {...register(commercialNameField, { required: requiredMsg, maxLength: 255, shouldUnregister: true })}
                    dataTestId={`${idPrefix}-commercial-name`}
                    defaultValue={contact.commercialName ?? ''}
                    label="Business Name"
                    maxLength={255}
                    error={commercialNameFieldErrors}
                    hideHelperText={!commercialNameFieldErrors}
                />
            )}
            {!isCommercial && (
                <>
                    <Input
                        {...register(firstNameField, { required: requiredMsg, maxLength: 50, shouldUnregister: true })}
                        dataTestId={`${idPrefix}-first-name`}
                        defaultValue={contact.firstName ?? ''}
                        label="First Name"
                        maxLength={50}
                        autoComplete="given-name"
                        error={firstNameFieldErrors}
                        hideHelperText={!firstNameFieldErrors}
                    />

                    <Input
                        {...register(middleInitialField, { maxLength: 1, shouldUnregister: true })}
                        dataTestId={`${idPrefix}-middle-initial`}
                        defaultValue={contact.middleInitial ?? ''}
                        label="Middle Initial"
                        autoComplete="additional-name"
                        maxLength={1}
                        hideHelperText={true}
                    />
                </>
            )}
            {!isCommercial && (
                <SpanInput
                    {...register(lastNameField, { required: requiredMsg, maxLength: 100, shouldUnregister: true })}
                    dataTestId={`${idPrefix}-last-name`}
                    defaultValue={contact.lastName ?? ''}
                    label="Last Name"
                    maxLength={100}
                    autoComplete="family-name"
                    error={lastNameFieldErrors}
                    hideHelperText={!lastNameFieldErrors}
                />
            )}
            <Input
                {...withHookFormMask(
                    register(phoneNumberField, {
                        required: requiredMsg,
                        validate: {
                            isPhoneNumber: v => {
                                return !formattedPhoneNumberRegex.test(v) ? 'Phone number is invalid' : undefined
                            },
                            isAllSameNumber: v => {
                                return allSameDigitPhoneNumber.test(parsePhoneNumber(v) ?? '') ? 'Phone number is invalid' : undefined
                            },
                        },
                        shouldUnregister: true,
                    }),
                    ['(999) 999-9999'],
                    { autoUnmask: true },
                )}
                dataTestId={`${idPrefix}-phone-number`}
                defaultValue={contact.phoneNumber ?? ''}
                label="Phone Number"
                type="tel"
                autoComplete="tel"
                error={phoneNumberFieldErrors}
                hideHelperText={!phoneNumberFieldErrors}
            />
            <Select
                {...register(phoneTypeField, { required: requiredMsg, shouldUnregister: true })}
                defaultValue={contact.phoneType ?? PhoneType.Cell}
                dataTestId={`${idPrefix}-phone-Type`}
                allowClear={false}
                clearOptionText="Please select phone type"
                label="Phone Type"
                hideHelperText={!phoneTypeFieldErrors}
                error={phoneTypeFieldErrors}
            >
                {phoneTypes.map(p => (
                    <SelectOption key={p} dataTestId={`${idPrefix}-${p}`} value={p}>
                        {p}
                    </SelectOption>
                ))}
            </Select>
            {enrollment.collectAlternatePhone && (
                <>
                    <Input
                        {...register(alternatePhoneNumberField, {
                            validate: { isValidPhone: v => isPhoneNumber(v!) },
                            shouldUnregister: true,
                        })}
                        dataTestId={`${idPrefix}-alternatePhone-number`}
                        label="Alternate Phone Number (optional)"
                        autoComplete="tel"
                        defaultValue={contact.alternatePhoneNumber ?? ''}
                        type="tel"
                        maxLength={10}
                        error={alternatePhoneNumberFieldErrors}
                        hideHelperText={!alternatePhoneNumberFieldErrors}
                    />
                    <Select
                        {...register(alternatePhoneTypeField, { shouldUnregister: true })}
                        defaultValue={contact.alternatePhoneType?.toString()}
                        dataTestId={`${idPrefix}-alternatePhone-Type`}
                        allowClear={false}
                        clearOptionText="Please select alternate phone type"
                        label="Alternate Phone Type"
                        hideHelperText={!alternatePhoneTypeFieldErrors}
                        error={alternatePhoneTypeFieldErrors}
                    >
                        {phoneTypes.map(p => (
                            <SelectOption key={p} dataTestId={`${idPrefix}-alternate-${p}`} value={p}>
                                {p}
                            </SelectOption>
                        ))}
                    </Select>
                </>
            )}
            {isMobilePhone && (
                <SpanColumn>
                    <LegalCheckBox
                        {...register(isMobilePhoneMarketableField, { shouldUnregister: true })}
                        dataTestId={`${idPrefix}-is-mobile-phone-marketable`}
                        defaultChecked={contact.isMobilePhoneMarketable ?? true}
                        label="By checking this box, you authorize us to reach out to you for 
                        customer service and information about new products at this phone number."
                    />
                </SpanColumn>
            )}

            <SpanInput
                {...register(emailField, { required: requiredMsg, pattern: { value: emailRegex, message: 'Invalid Email' }, shouldUnregister: true })}
                dataTestId={`${idPrefix}-email`}
                label="Email Address"
                defaultValue={contact.emailAddress ?? ''}
                type="email"
                autoComplete="email"
                maxLength={100}
                error={emailFieldErrors}
                hideHelperText={!emailFieldErrors}
                disabled={isEmailFieldLocked}
            />
            {showMarketingCheckBox && (
                <SpanColumn>
                    <EmailMarketingCheckbox isEmailMarketable={contact.isEmailMarketable ?? false} formKey={formKey} idPrefix={idPrefix} />
                </SpanColumn>
            )}
        </Layout2fr1frColumn>
    )
}
interface CustomerContactFormProps {
    readonly formKey: string
    readonly idPrefix: string
    readonly offerAccountType?: OfferAccountType
    readonly contact: ContactModel
    readonly showMarketingCheckBox: boolean
}
