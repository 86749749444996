import * as React from 'react'
import { useSelector } from 'react-redux'
import { Tab, TabList, TabPanel } from 'react-tabs'

import styled from 'styled-components'

import { UserProfileAccount, UserProfileServiceAddress } from '@igs-web/common-models/models'
import { MyAccountTabs } from '@igs-web/common-ui-components/_molecules/myaccount-tabs'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { fontSizeMedium, lightAccent, primaryBase } from '@igs-web/common-ui-components/styles/theme'

import { BillingAccountBillHistoryTableWrapper } from '../../billing/billing-accounts/billing-account-bill-history-table-wrapper'
import { BillingAccountPaymentHistory } from '../../billing/billing-accounts/billing-account-payment-history'
import { BudgetBillingDetails } from '../../budget-billing/budget-billing-details'
import { budgetBillingRedux } from '../../budget-billing/budget-billing-redux'
import { CommonReduxState } from '../../common-redux'
import { DocumentTable } from '../../documents/document-table'
import { useMyAccountDashboard } from '../../myaccount/dashboard-context'
import { UsageDetails } from '../../usage/usage-details'

const SelectTabMessage = styled.div`
    background-color: ${lightAccent};
    padding: ${Spacing.Large};
    text-align: center;
`

const BillingAccountNumberLabel = styled.h4`
    color: ${primaryBase};
    font-size: ${fontSizeMedium};
    text-align: center;
`
const AddressDisplay = styled.p`
    font-weight: bold;
    color: ${primaryBase};
    font-size: ${fontSizeMedium};
    text-align: center;
`

const BillingTabPanelHeader = ({ billingAccountNumber }: BillingTabPanelHeaderProps) => {
    return <BillingAccountNumberLabel>Billing Acct #{billingAccountNumber}</BillingAccountNumberLabel>
}

interface BillingTabPanelHeaderProps {
    readonly billingAccountNumber: string
}

const AccountTabPanelHeader = ({ serviceAddress }: AccountTabPanelHeaderProps) => {
    return (
        <AddressDisplay>
            {serviceAddress?.address1}
            {serviceAddress?.address2 ? ' ' + serviceAddress.address2 : ''}, {serviceAddress?.city}, {serviceAddress?.state} {serviceAddress?.zipCode}
        </AddressDisplay>
    )
}

interface AccountTabPanelHeaderProps {
    readonly serviceAddress: UserProfileServiceAddress
}

export const BillingAccountTabs = ({ selectedAccount }: Props) => {
    const initialTab = -1
    const [tabIndex, setTabIndex] = React.useState(initialTab)

    const { billingAccount, serviceAddress } = useMyAccountDashboard()

    const billingResponse = useSelector((store: CommonReduxState) => budgetBillingRedux.selectors.selectData(store, billingAccount?.billingAccountNumber || ''))
    const showBudgetBill = !!billingResponse?.lastInvoiceBudgetBillingInformation.length

    const accountIds = billingAccount?.partyAccountIds

    React.useEffect(() => {
        setTabIndex(initialTab)
    }, [billingAccount, serviceAddress])

    if (!serviceAddress) {
        throw Error('Unable to render BillingAccountTabs without a serviceAccount')
    }

    return (
        <>
            <MyAccountTabs className="react-tabs myaccount-detail-tabs" selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
                <TabList>
                    {showBudgetBill && (
                        <Tab data-testid="budget-billing-tab" tabIndex="0">
                            Budget Billing
                        </Tab>
                    )}
                    <Tab data-testid="commodity-details-payments-tab" tabIndex="0">
                        Payments
                    </Tab>
                    <Tab data-testid="commodity-details-billing-tab" tabIndex="0">
                        Bills
                    </Tab>
                    <Tab data-testid="usage-and-rates-tab" tabIndex="0">
                        Usage & Rates
                    </Tab>
                    <Tab data-testid="documents-tab" tabIndex="0">
                        Documents
                    </Tab>
                </TabList>
                {showBudgetBill && (
                    <TabPanel>
                        <BillingTabPanelHeader billingAccountNumber={billingAccount?.billingAccountNumber ?? ''} />
                        <BudgetBillingDetails billingAccountNumber={billingAccount?.billingAccountNumber ?? ''} />
                    </TabPanel>
                )}
                <TabPanel>
                    <BillingTabPanelHeader billingAccountNumber={billingAccount?.billingAccountNumber ?? ''} />
                    <BillingAccountPaymentHistory accountIds={accountIds || []} />
                </TabPanel>
                <TabPanel>
                    <BillingTabPanelHeader billingAccountNumber={billingAccount?.billingAccountNumber ?? ''} />
                    <BillingAccountBillHistoryTableWrapper accountIds={accountIds || []} />
                </TabPanel>
                <TabPanel>
                    <AccountTabPanelHeader serviceAddress={serviceAddress ?? null} />
                    <UsageDetails account={selectedAccount} />
                </TabPanel>
                <TabPanel>
                    <AccountTabPanelHeader serviceAddress={serviceAddress} />
                    <DocumentTable accountId={selectedAccount.accountId} />
                </TabPanel>
            </MyAccountTabs>
            {tabIndex === -1 && (
                <SelectTabMessage>
                    <p>
                        <strong>Select from the items above to see additional details for this account.</strong>
                    </p>
                </SelectTabMessage>
            )}
        </>
    )
}

interface Props {
    readonly selectedAccount: UserProfileAccount
}
