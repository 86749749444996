import * as React from 'react'
import { Provider } from 'react-redux'

import { Store } from 'redux'
import { Persistor } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

import { LocalLoader } from '@igs-web/common-ui-components/_molecules/loading-indicator'
import { configurationApiClient } from '@igs-web/common-utilities/api/configuration-api-client'

import { AlertProvider } from '../alerts/alert-context'
import { CompanyProvider } from '../company/hooks/useCompany'

export const CommonWrapper = ({ companyName, store, persistor, children }: Props) => {
    const [renderReady, setRenderReady] = React.useState(false)
    React.useEffect(() => {
        const loadConfig = async () => {
            await configurationApiClient.loadConfiguration(companyName)
            setRenderReady(true)
        }
        loadConfig()
    }, [setRenderReady])

    return renderReady ? (
        <Provider store={store}>
            <PersistGate loading="" persistor={persistor}>
                <React.Suspense fallback={<LocalLoader isLoading={true} />}>
                    <CompanyProvider companyName={companyName}>
                        <AlertProvider>{children}</AlertProvider>
                    </CompanyProvider>
                </React.Suspense>
            </PersistGate>
        </Provider>
    ) : (
        <React.Fragment />
    )
}

interface Props {
    readonly companyName: string
    readonly store: Store<unknown>
    readonly persistor: Persistor
    readonly children: React.ReactNode
}
