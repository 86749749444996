import { FormApi } from 'final-form'
import * as React from 'react'
import { FormRenderProps } from 'react-final-form'
import styled from 'styled-components'

import { SubmitButton } from '@igs-web/common-components/_molecules/final-form/submit-button'
import { ChangeEmailRequest } from '@igs-web/common-models/models/user-profile-model'
import { ErrorAlert } from '@igs-web/common-ui-components/_atoms/forms/alert'
import { HtmlForm } from '@igs-web/common-ui-components/_atoms/forms/html-form'
import { TypedForm } from '@igs-web/common-ui-components/_atoms/forms/typed-form'
import { EmailField } from '@igs-web/common-ui-components/_molecules/form-inputs/email-field'
import { PasswordField } from '@igs-web/common-ui-components/_molecules/form-inputs/password-field'
import { GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { InfoText, InfoTextType } from '@igs-web/common-ui-components/_molecules/info-text'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'
import { clearFormValues, formError } from '@igs-web/common-utilities/services/form-service'
import { composeValidators, mustMatch, required } from '@igs-web/common-utilities/utilities/form-validation'

const SubmitContainer = styled.div`
    text-align: right;
`

export const ChangeEmailForm = ({ onComplete }: Props): JSX.Element => {
    const changeEmail = async (request: ChangeEmailRequest, form: FormApi): Promise<object | undefined> => {
        try {
            await customerInfoApiClient.updateEmailAddress(request.email, request.currentPassword)
            onComplete()
            clearFormValues(form)
        } catch (e) {
            const message = 'Update email failed'
            return formError(message)
        }
    }

    return (
        <TypedForm<ChangeEmailRequest>
            onSubmit={changeEmail}
            render={({ handleSubmit, submitSucceeded, submitError }: FormRenderProps): JSX.Element => (
                <HtmlForm onSubmit={handleSubmit}>
                   {submitSucceeded && <>
                        <InfoText infoTextType={InfoTextType.Success}>Email Changed Successfully</InfoText>
                        <GridSpacer spacing={Spacing.Small}/>
                    </>}
                    <ErrorAlert message={submitError} />
                    <EmailField<ChangeEmailRequest>
                        name="email"
                        autoComplete="newEmail"
                        id="myprofile-update-email"
                        dataTestId="myprofile-update-email"
                        validate={required('Email is required')}
                        label="New Email Address"
                    />
                    <EmailField
                        name="emailConfirm"
                        id="myprofile-update-email-confirm"
                        dataTestId="myprofile-update-email-confirm"
                        validate={composeValidators(required('Email Confirmation is required'), mustMatch('email', 'Emails must match'))}
                        label="Confirm New Email Address"
                    />
                    <PasswordField<ChangeEmailRequest>
                        name="currentPassword"
                        id="myprofile-update-email-password"
                        dataTestId="myprofile-update-email-password"
                        validate={required('Current Password is required')}
                        label="Current Password"
                    />
                    <SubmitContainer>
                        <SubmitButton id="change-email-submit" dataTestId="change-email-submit">
                            SAVE CHANGES
                        </SubmitButton>
                    </SubmitContainer>
                </HtmlForm>
            )}
        />
    )
}

interface Props {
    readonly onComplete: () => void
}
