import * as React from 'react'
import { useSelector } from 'react-redux'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { ContactModel } from '@igs-web/common-models/models/contact-model'
import { EnrollmentModel } from '@igs-web/common-models/models/enrollment-model'
import { LineOfBusinessCode } from '@igs-web/common-models/models/line-of-business'
import { UserProfileAccount, UserProfileServiceAddress } from '@igs-web/common-models/models/user-profile-model'
import { reMapAepUlob } from '@igs-web/common-utilities/utilities/utility-service'

import { OfferSelectors } from '../../enrollment/offer-redux'
import { checkoutActions, checkoutInitialState, checkoutReducer, CheckoutState } from './checkout-reducer'

type CheckoutContextState = EnrollmentModel

interface CheckoutContextDispatch {
    readonly setEnrollmentState: (model: EnrollmentModel) => void
    readonly setContactInfo: (defaultContact: ContactModel, isPaperless: boolean) => void
    readonly setContactLoggedIn: (
        defaultContact: ContactModel,
        isPaperless: boolean,
        selectedServiceAddress: UserProfileServiceAddress,
        accountsForAddress: ReadonlyArray<UserProfileAccount>,
    ) => void
}

const CheckoutContextState = React.createContext<CheckoutContextState | undefined>(undefined)
const CheckoutContextDispatch = React.createContext<CheckoutContextDispatch | undefined>(undefined)

export const useCheckoutState = (): CheckoutContextState => {
    const context = React.useContext(CheckoutContextState)
    if (!context) {
        throw Error('Must use useCheckoutState inside of CheckoutContextProvider')
    }

    return context
}

export const useCheckoutDispatch = (): CheckoutContextDispatch => {
    const context = React.useContext(CheckoutContextDispatch)
    if (!context) {
        throw Error('Must use useCheckoutDispatch inside of CheckoutContextProvider')
    }

    return context
}

export const CheckoutProvider = ({ children, enrollmentState, storageKey }: CheckoutProviderProps): JSX.Element => {
    const initialState: CheckoutState = {
        enrollmentState: enrollmentState ? { ...enrollmentState } : { ...checkoutInitialState.enrollmentState },
        storageKey,
    }
    const [state, dispatch] = React.useReducer(checkoutReducer, initialState)
    const selectedOffers = useSelector((store: CommonReduxState) => OfferSelectors.selectSelectedOffers(store))

    const stateValue = state.enrollmentState

    const dispatchValue = {
        setEnrollmentState: model => dispatch(checkoutActions.setEnrollment(model)),
        setContactInfo: (defaultContact, isPaperless) => dispatch(checkoutActions.setContactInfo({ defaultContact, isPaperless })),
        setContactLoggedIn: (
            defaultContact: ContactModel,
            isPaperless: boolean,
            selectedServiceAddress: UserProfileServiceAddress,
            accountsForAddress: ReadonlyArray<UserProfileAccount>,
        ) => {
            dispatch(checkoutActions.setContactInfo({ defaultContact, isPaperless }))
            dispatch(checkoutActions.setServiceAddress(selectedServiceAddress))
            const selectedOfferGasUtility = selectedOffers.find(o => o.primaryProduct.lineOfBusinessCode === LineOfBusinessCode.Gas)?.primaryProduct.ulob
            const selectedOfferElectricUtility = selectedOffers.find(o => o.primaryProduct.lineOfBusinessCode === LineOfBusinessCode.Electric)?.primaryProduct
                .ulob
            const gasAccount = accountsForAddress.find(
                a => a.lineOfBusinessCode === LineOfBusinessCode.Gas && a.utilityCode === selectedOfferGasUtility?.utilityCode,
            )
            const electricAccount = accountsForAddress.find(
                a => a.lineOfBusinessCode === LineOfBusinessCode.Electric && reMapAepUlob(a.utilityCode) === selectedOfferElectricUtility?.utilityCode,
            )
            if (gasAccount) {
                dispatch(
                    checkoutActions.setProductDetail({
                        shoppingCartItemKey: '',
                        lineOfBusinessCode: LineOfBusinessCode.Gas,
                        accountNumber: gasAccount.accountNumber,
                        meterNumber: gasAccount.meterNumber,
                        useDefaultContact: true,
                        contact: { ...defaultContact },
                    }),
                )
            }

            if (electricAccount) {
                dispatch(
                    checkoutActions.setProductDetail({
                        shoppingCartItemKey: '',
                        lineOfBusinessCode: LineOfBusinessCode.Electric,
                        accountNumber: electricAccount.accountNumber,
                        meterNumber: electricAccount.meterNumber,
                        useDefaultContact: true,
                        contact: { ...defaultContact },
                    }),
                )
            }
        },
    }

    return (
        <CheckoutContextState.Provider value={stateValue}>
            <CheckoutContextDispatch.Provider value={dispatchValue}>{children}</CheckoutContextDispatch.Provider>
        </CheckoutContextState.Provider>
    )
}

interface CheckoutProviderProps {
    readonly children: React.ReactNode
    readonly enrollmentState?: EnrollmentModel
    readonly storageKey: string
}
