import * as React from 'react'
import { useForm } from 'react-hook-form'
import { MdLocationOn } from 'react-icons/md'

import { useCheckoutDispatch, useCheckoutState } from '@igs-web/common-components/domain/checkout/hooks/use-checkout'
import { StepNav } from '@igs-web/common-components/domain/checkout/steps/step-nav'
import { StateCode } from '@igs-web/common-models/constants/states'
import { Address, PremiseResponse } from '@igs-web/common-models/models'
import { FormWrapper } from '@igs-web/common-ui-components/_atoms/form-wrapper'
import { IconHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'
import { Input } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { Grid1fr1fr } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { PremiseSearchAddressRequest, enrollmentApiClient } from '@igs-web/common-utilities/api/enrollment-api-client'

import { useCheckoutWizardMessagesDispatch } from '../../checkout-wizard-messages/checkout-wizard-messages-context'

import { ServiceAddressSearchSteps } from './service-address-step'

const UTILITY_CODE = 'AGL'

export const AddressSearchStep = ({ setSearchResultsState, setCurrentStep }: SearchStepProps) => {
    const { setEnrollmentState } = useCheckoutDispatch()
    const enrollmentState = useCheckoutState()
    const { setShowCantMatchAddressMessage, setShowIsCommercialServiceTypeAddressMessage } = useCheckoutWizardMessagesDispatch()

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<serviceAddressSearchFields>()

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const onSubmit = async (data: serviceAddressSearchFields) => {
        setShowCantMatchAddressMessage(false)
        setShowIsCommercialServiceTypeAddressMessage(false)

        const { address1, address2, city, state, zipCode } = data

        const enteredServiceAddress: Address = {
            address1,
            address2,
            city,
            state,
            zipCode,
        }
        setEnrollmentState({
            ...enrollmentState,
            serviceAddress: enteredServiceAddress,
        })

        const address: PremiseSearchAddressRequest = { address1, address2, city, zipCode }
        const results = await enrollmentApiClient.serviceAddressLookup(UTILITY_CODE, address)

        if (results.length > 0) {
            setSearchResultsState(results)
            setCurrentStep(ServiceAddressSearchSteps.results)
        } else {
            setShowCantMatchAddressMessage(true)
        }
    }

    return (
        <FormWrapper onSubmit={handleSubmit(onSubmit)}>
            <IconHeading>
                <MdLocationOn /> Service Address
            </IconHeading>
            <Input
                {...register('address1', { required: 'Required' })}
                label="Service Address"
                defaultValue={enrollmentState.serviceAddress?.address1}
                dataTestId="premise-search-address-1"
                error={errors.address1?.message}
                hideHelperText={!errors.address1}
            />

            <Input
                {...register('address2')}
                label="Service Address Line 2"
                defaultValue={enrollmentState.serviceAddress?.address2}
                dataTestId="service-address-search-address-2"
                hideHelperText={true}
            />
            <Grid1fr1fr>
                <Input
                    {...register('city')}
                    label="City"
                    defaultValue={enrollmentState.serviceAddress?.city}
                    dataTestId="premise-search-city"
                    hideHelperText={true}
                />
                <Input
                    {...register('state')}
                    label="State"
                    defaultValue={StateCode.GA}
                    dataTestId="premise-search-state"
                    readOnly={true}
                    hideHelperText={true}
                />
            </Grid1fr1fr>
            <Input
                {...register('zipCode')}
                label="ZIP Code"
                defaultValue={enrollmentState.serviceAddress?.zipCode}
                dataTestId="premise-search-zipCode"
                hideHelperText={true}
                maxLength={5}
            />
            <StepNav stepFwdButtonText="CONTINUE" />
        </FormWrapper>
    )
}

interface SearchStepProps {
    readonly setSearchResultsState: React.Dispatch<React.SetStateAction<ReadonlyArray<PremiseResponse>>>
    readonly setCurrentStep: React.Dispatch<React.SetStateAction<string>>
}

interface serviceAddressSearchFields {
    readonly address1: string
    readonly address2: string
    readonly city: string
    readonly state: StateCode
    readonly zipCode: string
}
