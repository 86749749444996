import * as React from 'react'

import styled from 'styled-components'

import { AccountDetailsCard } from '@igs-web/common-components/domain/account-details/account-details-card/account-details-card'
import { AccountDetailsCardBody } from '@igs-web/common-components/domain/account-details/account-details-card/account-details-card-body'
import { AccountDetailsCardHeader } from '@igs-web/common-components/domain/account-details/account-details-card/account-details-card-header'
import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { ButtonStyleType } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { RouterLinkButton } from '@igs-web/common-ui-components/_atoms/link/link-button'
import { PhoneNumberLink, PhoneNumberLinkButton } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

import * as Routes from 'domain/routes'

const StyledCardBody = styled(AccountDetailsCardBody)`
    grid-template-rows: min-content min-content 1fr;
    align-items: end;
`

const CtaContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${Spacing.Small};
    align-self: end;
    grid-row-gap: ${Spacing.Medium};
    @media (max-width: ${Breakpoint.Desktop}) {
        grid-template-columns: 1fr;
    }

    @media (max-width: ${Breakpoint.Tablet}) {
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: ${Breakpoint.MobileSmall}) {
        grid-template-columns: 1fr;
    }
    .btn {
        padding: ${Spacing.Small};
    }
`

const ActiveCardBody = ({ hwAccountCount }: ActiveCardBodyProps) => {
    const company = useCompany()
    return (
        <StyledCardBody>
            <div>
                {hwAccountCount === 1 ? (
                    <strong>Need to make a service request, add a service address, or have a question about your account?</strong>
                ) : (
                    <strong>Need to make a service request or have a question about your account?</strong>
                )}
            </div>
            <div>
                Contact us online or call <PhoneNumberLink phoneNumber={company.phoneNumber.homeWarranty} />
                <br />
                {company.businessHours.homeWarrantyFull}
            </div>
            <CtaContainer>
                <RouterLinkButton to={Routes.HwServiceRequests}>REQUEST ONLINE</RouterLinkButton>
                <PhoneNumberLinkButton buttonStyle={ButtonStyleType.Secondary} phoneNumber={company.phoneNumber.homeWarranty}>
                    Call Now
                </PhoneNumberLinkButton>
            </CtaContainer>
        </StyledCardBody>
    )
}

interface ActiveCardBodyProps {
    readonly hwAccountCount: number
}

const InactiveCardBody = () => {
    const company = useCompany()
    return (
        <StyledCardBody>
            <div>
                <strong>Thank you for being a prior Home Warranty customer.</strong>
            </div>
            <div>
                You may enroll at any time, either on the plan you previously selected or a different plan, by clicking on SIGN UP below or by calling{' '}
                <PhoneNumberLink phoneNumber={company.phoneNumber.homeWarranty} /> {company.businessHours.homwWarrantyAbbreviated}.
            </div>
            <RouterLinkButton to={Routes.HwProductSelection}>Sign Up</RouterLinkButton>
        </StyledCardBody>
    )
}

export const CustomerServicePanel = ({ isInactive, hwAccountCount }: Props) => {
    return (
        <AccountDetailsCard>
            <AccountDetailsCardHeader>Customer Service</AccountDetailsCardHeader>
            {!isInactive && <ActiveCardBody hwAccountCount={hwAccountCount} />}
            {isInactive && <InactiveCardBody />}
        </AccountDetailsCard>
    )
}

interface Props {
    readonly isInactive: boolean
    readonly hwAccountCount: number
}
