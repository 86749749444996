import * as Routes from 'domain/routes'
import * as React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { homeWarrantyPaymentProfileRedux } from '@igs-web/common-components/domain/payment-profile/hw-payment-profile-redux'
import { PastDueBalanceAlertDisplay } from '@igs-web/common-components/domain/payment/past-due-balance-alert'
import { ButtonStyleType } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { RouterLinkButton } from '@igs-web/common-ui-components/_atoms/link/link-button'
import { NoWrapText } from '@igs-web/common-ui-components/_atoms/typography'
import { GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { lightLight } from '@igs-web/common-ui-components/styles/theme'
import { formatAsMoney } from '@igs-web/common-utilities/utilities/currency-utilities'

const StyledRouterLinkButton = styled(RouterLinkButton)`
    color: ${lightLight};
    @media (max-width: ${Breakpoint.Mobile}) {
        margin-left: unset;
        margin-top: ${Spacing.Medium};
    }
    margin-left: auto;
    border-color: ${lightLight};
    &:hover {
        background-color: var(--colorway-rgb);
        border-color: ${lightLight};
    }
`
export const HwPastDueBalanceAlert = ({ accountId }: Props) => {
    const paymentProfile = useSelector((store: CommonReduxState) => homeWarrantyPaymentProfileRedux.selectors.selectData(store, accountId))
    if (paymentProfile && paymentProfile?.accountBalance > 0) {
        return (
            <>
                <PastDueBalanceAlertDisplay>
                    <strong>
                        You have a past due <NoWrapText>balance of {formatAsMoney(paymentProfile?.accountBalance)}</NoWrapText>
                    </strong>
                    <StyledRouterLinkButton buttonStyle={ButtonStyleType.Secondary} to={Routes.HwManagePayment}>
                        Update Payment Method
                    </StyledRouterLinkButton>
                </PastDueBalanceAlertDisplay>
                <GridSpacer spacing={Spacing.Large} />
            </>
        )
    }

    return <React.Fragment />
}

interface Props {
    readonly accountId?: number
}
