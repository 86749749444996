import * as React from 'react'

import styled, { css } from 'styled-components'

import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { fullBleedWidth } from '@igs-web/common-ui-components/styles/theme'

export const commonGridCss = css`
    display: grid;
    grid-row-gap: ${Spacing.Large};
    grid-column-gap: ${Spacing.ExtraLarge};
    background: var(--background);
    width: 100%;

    @media (max-width: ${Breakpoint.Tablet}) {
        grid-template-columns: 1fr;
    }
`

export const ContainerGrid = styled.div<ContainerGridProps>`
    display: grid;
    grid-template-columns: 1fr ${({ $maxWidth: maxWidth }) => maxWidth} 1fr;
    padding: 0 0;
    background: var(--background);
    width: 100%;
    & > * {
        grid-column: 2;
    }
    @media (max-width: ${({ $maxWidth: maxWidth }) => maxWidth}) {
        grid-template-columns: 1fr;
        & > * {
            grid-column: 1;
        }
    }
`

interface ContainerGridProps {
    readonly $maxWidth: Breakpoint
}

export const ContainerGridFull = styled(ContainerGrid)`
    grid-template-columns: 1fr ${fullBleedWidth} 1fr;

    @media (max-width: ${fullBleedWidth}) {
        grid-template-columns: 1fr;
    }
`

export const Grid1fr1fr = styled.div`
    grid-template-columns: 1fr 1fr;
    ${commonGridCss}
`

export const Grid1fr2fr = styled.div`
    grid-template-columns: 1fr 2fr;
    ${commonGridCss}
`

export const Grid2fr1fr = styled.div`
    grid-template-columns: 2fr 1fr;
    ${commonGridCss}
`

export const Grid1fr1fr1fr = styled.div`
    grid-template-columns: 1fr 1fr 1fr;
    ${commonGridCss}
`

export const GridSpacer = styled.div<GridSpacerProps>`
    margin-bottom: ${props => props.spacing};
    height: auto;
    @media (max-width: $spacer-break-point) {
        margin-bottom: 10%;
    }
`
export const GridSpacerNoPadding = styled(GridSpacer)`
    padding: 0px;
`

interface GridSpacerProps {
    readonly spacing: Spacing
}
export const Layout1frColumn = ({ children, className, ...htmlProps }: LayoutProps) => (
    <div className={`container-grid container-theme ${className}`} {...htmlProps}>
        {children}
    </div>
)
export const Layout1frColumnFull = ({ children, className, ...htmlProps }: LayoutProps) => (
    <div className={`container-grid container-theme wide-grid ${className}`} {...htmlProps}>
        {children}
    </div>
)
export const Layout1fr1frColumn = ({ children, className, ...htmlProps }: LayoutProps) => (
    <Grid1fr1fr className={`layout-grid ${className}`} {...htmlProps}>
        {children}
    </Grid1fr1fr>
)
export const Layout1fr1fr1frColumn = ({ children, className, ...htmlProps }: LayoutProps) => (
    <Grid1fr1fr1fr className={`layout-grid ${className}`} {...htmlProps}>
        {children}
    </Grid1fr1fr1fr>
)
export const Layout1fr2frColumn = ({ children, className, ...htmlProps }: LayoutProps) => (
    <Grid1fr2fr className={`layout-grid ${className}`} {...htmlProps}>
        {children}
    </Grid1fr2fr>
)
export const Layout2fr1frColumn = ({ children, className, ...htmlProps }: LayoutProps) => (
    <Grid2fr1fr className={`layout-grid ${className}`} {...htmlProps}>
        {children}
    </Grid2fr1fr>
)

interface LayoutProps extends React.HTMLAttributes<HTMLDivElement> {
    readonly children: React.ReactNode
    readonly className?: string
}
