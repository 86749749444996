import * as React from 'react'

import styled from 'styled-components'

import { useMyAccountDashboard } from '@igs-web/common-components/domain/myaccount/dashboard-context'
import { CurrentInformation } from '@igs-web/common-components/domain/user-profile/current-information'
import { HWMyProfileAccordion } from '@igs-web/common-components/domain/user-profile/hw-myprofile-accordion'
import { MyProfileProvider } from '@igs-web/common-components/domain/user-profile/myprofile-context'
import { ProfileSection } from '@igs-web/common-components/domain/user-profile/user-profile-routes'
import { LineOfBusinessCode } from '@igs-web/common-models/models'
import { AccountStatus } from '@igs-web/common-models/models/account-model'
import { GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { InfoText, InfoTextType } from '@igs-web/common-ui-components/_molecules/info-text'
import { PageContentContainer } from '@igs-web/common-ui-components/_molecules/page-content-container'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

import { NavigationBanner } from 'molecules/header/navigation-banner'
import { ScrollToTop } from 'molecules/routing/scroll-to-top'

const CenteredInfoTextMessage = styled.p`
    text-align: center;
`
const MaxWidthContainer = styled(PageContentContainer)`
    width: 100%;
`

export const HwAccountInformationPage = () => {
    const { profile } = useMyAccountDashboard()
    const hashValue = location.hash && location.hash.substring(1)
    /*in the event of a redirect from the Update My Information link on the ContentSidebar, returns the ProfileSections 
    for ChangeEmail and ChangePhone, as both of those sections are required to be open in case of that specific redirect*/
    const profileSections: ReadonlyArray<ProfileSection> | undefined = hashValue.split(',').map(section => {
        return ProfileSection[section]
    })

    const hasActiveAccount =
        profile.hasScanaHW &&
        profile.accounts.some(account => account.status === AccountStatus.active && account.lineOfBusinessCode === LineOfBusinessCode.HomeWarranty)

    return (
        <>
            {profile && (
                <MyProfileProvider userProfileId={profile.userProfileId}>
                    <ScrollToTop />
                    <NavigationBanner title="Home Warranty - Account Information" />
                    <MaxWidthContainer>
                        {profile.hasGasAccount && (
                            <>
                                <GridSpacer spacing={Spacing.Large} />
                                <InfoText infoTextType={InfoTextType.Informational}>
                                    Any changes to account information will also be reflected on your SCANA Energy Natural Gas account.
                                </InfoText>
                            </>
                        )}
                        {!hasActiveAccount && (
                            <InfoText hideIcon={true}>
                                <CenteredInfoTextMessage>
                                    You do not currently have a Home Warranty plan. <br />
                                    You can update your My Account information below.
                                </CenteredInfoTextMessage>
                            </InfoText>
                        )}
                        <div>
                            <CurrentInformation />
                            <HWMyProfileAccordion initialPanel={profileSections.length > 0 ? profileSections : undefined} />
                        </div>
                    </MaxWidthContainer>
                </MyProfileProvider>
            )}
        </>
    )
}
