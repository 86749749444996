import React from 'react'
import { useSelector } from 'react-redux'

import _sortBy from 'lodash-es/sortBy'

import { CompanyVerbiage, Verbiage } from '@igs-web/common-components/domain/company/companyVerbiage'
import { BillingAccountInvoice } from '@igs-web/common-models/models/billing-account-invoice'
import { ChoiceInvoice } from '@igs-web/common-models/models/choice-transaction-history'
import { LegacyBill } from '@igs-web/common-models/models/legacy-data'
import { BillingAccountReportRequest } from '@igs-web/common-models/models/report-requests/billing-account-report-request'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'
import { formatAsMoney } from '@igs-web/common-utilities/utilities/currency-utilities'
import { formatDate } from '@igs-web/common-utilities/utilities/date-utilities'
import { downloadBlob } from '@igs-web/common-utilities/utilities/document-utilities'

import { CommonReduxState } from '../common-redux'
import { useCompany } from '../company/hooks/useCompany'
import { DataTable } from '../data-table/data-table'
import { DefaultEmptyTable } from '../data-table/default-empty-table'
import { DateFilter } from '../data-table/filters/date-filter'
import { TableColumn } from '../data-table/models/table-column'
import { TableDataType } from '../data-table/models/table-data-types'
import { legacyBillRedux } from '../myaccount/legacy-data/legacy-bills-redux'
import { LegacyInvoicePdfLink } from '../myaccount/legacy-data/legacy-invoice-pdf-link'

import { InvoicePdfLink } from './invoice-pdf-link'

interface BillTableRecord {
    readonly type: string
    readonly invoiceLink: React.ReactNode
    readonly invoiceDate?: Date
    readonly dueDate: string
    readonly amountDisplay: string
    readonly invoiceId?: number
}

const mapToTableRecord = (invoice: ChoiceInvoice, accountId: number): BillTableRecord => ({
    type: 'invoice',
    invoiceLink: <InvoicePdfLink accountId={accountId} invoiceNumber={invoice.invoiceNumber} />,
    invoiceDate: invoice.invoiceDate,
    dueDate: formatDate(invoice.dueDate),
    amountDisplay: invoice.shouldDisplayAlternateAmount ? 'See Bill' : formatAsMoney(invoice.amount),
    invoiceId: invoice.invoiceId,
})

const mapLegacyToTableRecord = (bill: LegacyBill): BillTableRecord => ({
    type: 'invoice',
    invoiceLink: <LegacyInvoicePdfLink invoiceUrl={bill.invoiceUrl} invoiceDate={bill.invoiceDate} label="View Bill" />,
    invoiceDate: bill.invoiceDate,
    dueDate: bill.dueDateDisplay,
    amountDisplay: formatAsMoney(bill.amount),
    invoiceId: bill.invoiceId,
})

const getSortedInvoices = (invoices: ReadonlyArray<BillTableRecord>) => {
    return _sortBy(
        invoices,
        r => r.invoiceDate,
        r => r.invoiceId,
    ).reverse()
}

const dedupeBillingAccountInvoices = (invoices: ReadonlyArray<BillingAccountInvoice>) => {
    return invoices.filter((invoice, index, self) => index === self.findIndex(t => t.invoiceNumber === invoice.invoiceNumber))
}

const downloadPdf = async (accountId: number) => {
    const blob = await customerInfoApiClient.getBillsPdfByAccountId(accountId)
    downloadBlob(blob, 'bills', 'pdf')
}

const downloadBillingAccountPdf = async (accountIds: ReadonlyArray<number>, billingAccountNumber: string, companyName: string) => {
    const request: BillingAccountReportRequest = {
        accountIds,
        billingAccountNumber,
        companyName,
    }
    const blob = await customerInfoApiClient.getBillingAccountBillsPdfByAccountIds(request)
    downloadBlob(blob, 'bills', 'pdf')
}

const downloadCsv = async (accountId: number) => {
    const blob = await customerInfoApiClient.getBillsCsvByAccountId(accountId)
    downloadBlob(blob, 'bills', 'csv')
}

const downloadBillingAccountCsv = async (accountIds: ReadonlyArray<number>, billingAccountNumber: string, companyName: string) => {
    const request: BillingAccountReportRequest = {
        accountIds,
        billingAccountNumber,
        companyName,
    }
    const blob = await customerInfoApiClient.getBillingAccountBillsCsvByAccountIds(request)
    downloadBlob(blob, 'bills', 'csv')
}

const getColumnDefs = (companyVerbiage: CompanyVerbiage): ReadonlyArray<TableColumn> => [
    { header: 'Billing Date', accessor: 'invoiceDate', dataType: TableDataType.DateTime, filter: DateFilter },
    { header: 'View Bill', accessor: 'invoiceLink', dataType: TableDataType.ReactNode, disableSorting: true },
    { header: 'Due Date', accessor: 'dueDate', dataType: TableDataType.String, disableSorting: true },
    { header: companyVerbiage[Verbiage.billsTableAmountDueHeaderText], accessor: 'amountDisplay', dataType: TableDataType.String, disableSorting: true },
]

export const BillHistoryTable = ({ invoices, accountId }: Props): JSX.Element => {
    const paymentData = invoices.map(invoice => mapToTableRecord(invoice, accountId))
    const { phoneNumber } = useCompany()
    const rowData = getSortedInvoices([...paymentData])
    const { verbiage } = useCompany()
    const columnDefs = getColumnDefs(verbiage)
    return (
        <DataTable<BillTableRecord>
            tableTitle={'Bills'}
            columns={columnDefs}
            data={rowData}
            handlePdfDownload={() => downloadPdf(accountId)}
            handleCsvDownload={() => downloadCsv(accountId)}
            showWhenEmpty={<DefaultEmptyTable tableDataType="bills" phoneNumber={phoneNumber.customerSupport} />}
        />
    )
}

interface Props {
    readonly accountId: number
    readonly invoices: ReadonlyArray<ChoiceInvoice>
}

export const BillingAccountBillHistoryTable = ({ invoices, accountIds, billingAccountNumber, dataTestId }: BillingAccountBillHistoryTableProps) => {
    const { displayName, verbiage } = useCompany()
    const billData = dedupeBillingAccountInvoices(invoices).map(invoice => mapToTableRecord(invoice, invoice.accountId))
    const { phoneNumber } = useCompany()

    const legacyBills =
        useSelector((store: CommonReduxState) => legacyBillRedux.selectors.selectData(store, billingAccountNumber)) ?? ([] as ReadonlyArray<LegacyBill>)
    const legacyBillData = legacyBills.map(mapLegacyToTableRecord)

    const rowData = getSortedInvoices([...billData, ...legacyBillData])

    const handlePdfDownload = () => downloadBillingAccountPdf(accountIds, billingAccountNumber, displayName)
    const handleCvsDownload = () => downloadBillingAccountCsv(accountIds, billingAccountNumber, displayName)

    const columnDefs = getColumnDefs(verbiage)

    return (
        <DataTable<BillTableRecord>
            tableTitle={'Bills'}
            columns={columnDefs}
            data={rowData}
            handlePdfDownload={billData.length ? handlePdfDownload : undefined}
            handleCsvDownload={billData.length ? handleCvsDownload : undefined}
            showWhenEmpty={<DefaultEmptyTable tableDataType="bills" phoneNumber={phoneNumber.customerSupport} />}
            dataTestId={dataTestId}
        />
    )
}
interface BillingAccountBillHistoryTableProps {
    readonly invoices: ReadonlyArray<BillingAccountInvoice>
    readonly accountIds: ReadonlyArray<number>
    readonly billingAccountNumber: string
    readonly dataTestId: string
}
