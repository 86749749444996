import * as React from 'react'
import { phoneNumbers } from 'root/constants'
import { PhoneNumberLink } from '../_atoms/link/link-phone-number'

declare global {
    interface Window {
        // eslint-disable-next-line functional/prefer-readonly-type
        readonly IGS?: {
            readonly settings: {
                readonly cleanedPhoneNumberOverride: string
            }
        }
    }
}

const getHelpPhoneNumber = (): string => {
    const phoneNumber = window.IGS && window.IGS.settings && window.IGS.settings.cleanedPhoneNumberOverride

    return phoneNumber || phoneNumbers.help
}

export const HelpPhoneNumberLink = (): JSX.Element => {
    const helpPhoneNumber = getHelpPhoneNumber()
    return <PhoneNumberLink phoneNumber={helpPhoneNumber} />
}
