import * as React from 'react'
import { Tab, TabList, TabPanel } from 'react-tabs'

import { UserProfileAccount } from '@igs-web/common-models/models'
import { MyAccountTabs } from '@igs-web/common-ui-components/_molecules/myaccount-tabs'

import { DocumentTable } from '../../documents/document-table'
import { RateDetails } from '../../rates/rate-details'
import { UsageDetails } from '../../usage/usage-details'

export const CommodityTabs = ({ selectedAccount }: Props) => {
    const initialTab = 0
    const [tabIndex, setTabIndex] = React.useState(initialTab)

    return (
        <MyAccountTabs className="react-tabs myaccount-detail-tabs" selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
            <TabList>
                <Tab>Usage</Tab>
                <Tab>Rates</Tab>
                <Tab>Documents</Tab>
            </TabList>

            <TabPanel>
                <UsageDetails account={selectedAccount} />
            </TabPanel>
            <TabPanel>
                <RateDetails accountId={selectedAccount.accountId} />
            </TabPanel>
            <TabPanel>
                <DocumentTable accountId={selectedAccount.accountId} />
            </TabPanel>
        </MyAccountTabs>
    )
}

interface Props {
    readonly selectedAccount: UserProfileAccount
}
