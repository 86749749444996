import React from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { CreateAccountWithLinkRequest } from '@igs-web/common-models/models/create-account-request'
import { Button, ButtonStyleType } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { Link as StandardLink } from '@igs-web/common-ui-components/_atoms/link/link'
import { Checkbox, Input } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { emailRegex } from '@igs-web/common-utilities/utilities/form-validation'
import { formattedPhoneNumberRegex } from '@igs-web/common-utilities/utilities/phone-utilities'

import { scanaAccountNumberRegex } from 'domain/account-number/scana-account-number-service'
import { LoginRoute } from 'domain/routes'

import { ButtonCell, LoginFormGrid, SpanColumns } from './authentication-components'

export const RegisterAccountForm = ({ onSubmit }: Props) => {
    const history = useHistory()
    const { externalWebsite } = useCompany()
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<RegisterAccountFormFields>()

    return (
        <form onSubmit={handleSubmit(data => onSubmit(data))}>
            <LoginFormGrid>
                <SpanColumns>
                    <Input
                        {...register('billingAccountNumber', {
                            required: 'Required',
                            pattern: { value: scanaAccountNumberRegex, message: 'Billing account should be 6-16 numbers long' },
                        })}
                        dataTestId="register-account-billing-account-number"
                        defaultValue=""
                        min={6}
                        max={16}
                        label="Billing Account Number"
                        error={errors.billingAccountNumber?.message}
                        hideHelperText={!errors.billingAccountNumber?.message}
                        onChange={e => (e.target.value = e.target.value.trim())}
                    />
                </SpanColumns>
                <SpanColumns>
                    <Input
                        {...register('email', { required: 'Required', pattern: { value: emailRegex, message: 'Invalid Email' } })}
                        dataTestId="register-account-email"
                        defaultValue=""
                        label="Email Address"
                        error={errors.email?.message}
                        hideHelperText={!errors.email?.message}
                        onChange={e => (e.target.value = e.target.value.trim())}
                    />
                </SpanColumns>
                <Input
                    {...register('lastName', {
                        validate: { required: value => (watch('phoneNumber') === '' && value === '' ? 'Phone Number or Last Name Required' : undefined) },
                    })}
                    dataTestId="register-account-last-name"
                    defaultValue=""
                    label="Last / Business Name"
                    maxLength={100}
                    autoComplete="family-name"
                    error={errors.lastName?.message}
                    hideHelperText={!errors.lastName?.message}
                    onChange={e => (e.target.value = e.target.value.trim())}
                />
                <Input
                    {...register('phoneNumber', {
                        pattern: { value: formattedPhoneNumberRegex, message: 'Invalid Phone Number' },
                        validate: { required: value => (watch('lastName') === '' && value === '' ? 'Phone Number or Last Name Required' : undefined) },
                    })}
                    dataTestId="register-account-phone-number"
                    defaultValue=""
                    autoComplete="tel"
                    type="tel"
                    label="Phone Number"
                    error={errors.phoneNumber?.message}
                    hideHelperText={!errors.phoneNumber?.message}
                    onChange={e => (e.target.value = e.target.value.trim())}
                />
                <SpanColumns>
                    <Checkbox
                        {...register('acceptTerms', { required: 'Required' })}
                        dataTestId="register-account-confirm-stop-date-terms"
                        label={
                            <>
                                I agree to the{' '}
                                <StandardLink target="_blank" href={`${externalWebsite}/home/terms-conditions`}>
                                    Terms and Conditions
                                </StandardLink>
                            </>
                        }
                        $error={!!errors.acceptTerms}
                    />
                </SpanColumns>
                <ButtonCell>
                    <Button buttonStyle={ButtonStyleType.Secondary} onClick={() => history.push(LoginRoute)}>
                        Cancel
                    </Button>
                    <Button type="submit">Submit</Button>
                </ButtonCell>
            </LoginFormGrid>
        </form>
    )
}
export interface RegisterAccountFormFields extends CreateAccountWithLinkRequest {
    readonly acceptTerms: boolean
}
interface Props {
    readonly onSubmit: (data: RegisterAccountFormFields) => void
}
