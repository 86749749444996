import * as React from 'react'
import { Link } from 'react-router-dom'

import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'

import { useCompany } from '../company/hooks/useCompany'

export const PaymentExtensionMessage = ({billingAccountNumber}: MoreTimeToPayMessageProps) => {
    const { phoneNumber, features, routes } = useCompany()

    if (features.canCreateOnlinePaymentExtension) {
        return ( <span>
            <Link to={routes.payment.paymentExtension(billingAccountNumber)}>Need more time to pay?</Link>
        </span>)
    } else {
        return (
            <span>
            If you need more time to pay please call: <PhoneNumberLink phoneNumber={phoneNumber.customerSupport} />
        </span>
        )
    }
}

interface MoreTimeToPayMessageProps {
   readonly billingAccountNumber?: string
}