import * as React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { Header3 } from '@igs-web/common-ui-components/_atoms/typography'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { headerColor } from '@igs-web/common-ui-components/styles/theme'
import { formatPhoneNumberForDisplay } from '@igs-web/common-utilities/utilities/phone-utilities'

import { CommonReduxState } from '../common-redux'
import { UserSelectors } from '../user/user-redux'
import { useMyProfile } from './myprofile-context'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'

const CurrentInformationContainer = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: ${Spacing.Medium};
    padding-bottom: ${Spacing.Medium};
`
const StyledHeader = styled(Header3)`
    color: ${headerColor};
`
const EmailSpan = styled.span`
@media (max-width: ${Breakpoint.Mobile}){

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 30ch;
    display: inline-block;
    vertical-align: bottom;
}
`

export const CurrentInformation = () => {
    const userProfile = useSelector((store: CommonReduxState) => UserSelectors.selectProfile(store))
    if (userProfile === undefined) {
        throw new Error('Unable to retrieve userProfile')
    }
    const { firstName, lastName, email, userIsMapped } = userProfile
    const { partyPhoneContacts } = useMyProfile()

    const allPhones = partyPhoneContacts?.reduce((a, { myAccountPartyPhoneContacts }) => [...a, ...myAccountPartyPhoneContacts], [])

    const allPrimaryPhones = allPhones
        ?.filter(ap => ap.isPrimary)
        .map(ap => formatPhoneNumberForDisplay(ap.phoneNumber))
        .join(', ')

    return (
        <>
            <CurrentInformationContainer>
                <StyledHeader>Current Information</StyledHeader>
                {!userIsMapped && (
                    <>
                        <div>
                            <strong>Customer Name: </strong> {firstName} {lastName}
                        </div>
                        <div>
                            <strong>Email: </strong>
                            <EmailSpan>{email}</EmailSpan>
                        </div>
                        <div>
                            <strong>Phone: </strong>
                            {allPrimaryPhones && allPrimaryPhones.length > 0 ? allPrimaryPhones : 'No phone contacts found for this account'}
                        </div>
                    </>
                )}
            </CurrentInformationContainer>
        </>
    )
}
