import React from 'react'
import { useHistory } from 'react-router-dom'

import { styled } from 'styled-components'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { Button, ButtonStyleType } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { Link as StandardLink } from '@igs-web/common-ui-components/_atoms/link/link'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { Header3 } from '@igs-web/common-ui-components/_atoms/typography'
import { InfoText, InfoTextType } from '@igs-web/common-ui-components/_molecules/info-text'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'

import { LayoutSection, LayoutSidebar, LayoutWithSidebar, LinkWithArrow } from 'molecules/layout/layout-styles'

import * as Routes from 'domain/routes'

import { TermsContainer } from './authentication-components'
import { RequestPasswordForm, RequestPasswordFormFields } from './request-password-form'

enum ResetPasswordStatus {
    NOTSENT = 'not-sent',
    SUCCESS = 'success',
    NOTFOUND = 'not-found',
}

const ReturnToSignInContainer = styled.p`
    text-align: right;
`

export const RequestPasswordPanel = () => {
    const history = useHistory()
    const [status, setStatus] = React.useState<ResetPasswordStatus>(ResetPasswordStatus.NOTSENT)
    const { phoneNumber, displayName, externalWebsite } = useCompany()

    const onSubmit = async ({ email }: RequestPasswordFormFields) => {
        try {
            await customerInfoApiClient.resetPassword(email, { showGlobalLoader: true, overrideNonUserPresentableErrors: 'Unable to Reset Password' })
            setStatus(ResetPasswordStatus.SUCCESS)
        } catch {
            setStatus(ResetPasswordStatus.NOTFOUND)
        }
    }

    return (
        <LayoutWithSidebar>
            <LayoutSection>
                {(status === ResetPasswordStatus.SUCCESS || status === ResetPasswordStatus.NOTFOUND) && (
                    <>
                        <InfoText infoTextType={InfoTextType.Informational}>
                            <h4>Check your email for further sign in instructions.</h4>
                            <p>
                                If we recognize this email address, you will receive an email with password reset instructions. Please remember to check your
                                spam folder.
                            </p>
                            <p>
                                If you don't remember your email, our customer service representatives are here to help at{' '}
                                <PhoneNumberLink phoneNumber={phoneNumber.help} />.
                            </p>
                        </InfoText>
                        <ReturnToSignInContainer>
                            <Button
                                data-testid="reset_password_return_to_signin_button"
                                buttonStyle={ButtonStyleType.Primary}
                                onClick={() => history.push(Routes.LoginRoute)}
                            >
                                RETURN TO SIGN IN
                            </Button>
                        </ReturnToSignInContainer>
                    </>
                )}
                {status !== ResetPasswordStatus.SUCCESS && status !== ResetPasswordStatus.NOTFOUND && (
                    <>
                        <p>Enter the email associated with your online account and we will send password reset instructions to your email.</p>
                        <RequestPasswordForm onSubmit={onSubmit} />

                        <TermsContainer>
                            By using this form and proceeding, you agree that you have read and accepted {displayName}'s{' '}
                            <StandardLink href={`${externalWebsite}/home/terms-conditions`}>Legal Terms and Conditions</StandardLink> and{' '}
                            <StandardLink href={`${externalWebsite}/home/privacy`}>Privacy.</StandardLink>
                        </TermsContainer>
                    </>
                )}
            </LayoutSection>

            <LayoutSidebar>
                <Header3>Forgot your email?</Header3>
                <p>
                    If you don’t remember the email address associated with your online account, please call <PhoneNumberLink phoneNumber={phoneNumber.help} />.
                </p>
                <Header3>New to SCANA Energy?</Header3>
                <br />
                <LinkWithArrow to={Routes.CreateAccount}>Create Online Account</LinkWithArrow>
            </LayoutSidebar>
        </LayoutWithSidebar>
    )
}
