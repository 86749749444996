import * as React from 'react'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { useOffersContextDispatch, useOffersContextState } from '@igs-web/common-components/domain/offers/context/offers-context'
import { OfferModel, ProductTypeCode } from '@igs-web/common-models/models/offer-model'
import { UserProfileAccount } from '@igs-web/common-models/models/user-profile-model'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { SubSectionHeadline } from '@igs-web/common-ui-components/_atoms/typography'
import { getEventModel, pushStartedCheckoutEvent } from '@igs-web/common-utilities/services/google-analytics-services'
import { formatDate } from '@igs-web/common-utilities/utilities/date-utilities'

import { OfferListView } from 'domain/offers/offer-list-view'
import * as Routes from 'domain/routes'

import { StyledRenewalDialogContainer } from './styled-offers-container'

export const RateChangeOffers = ({ selectedAccount }: RateChangeOffersProps) => {
    const company = useCompany()

    const { offers } = useOffersContextState()
    const { selectOffer } = useOffersContextDispatch()

    const handleSelectedOffer = (offer: OfferModel) => {
        pushStartedCheckoutEvent(getEventModel([offer]))
        selectOffer({ offerId: offer.offerId, navigateToRoute: Routes.RateChangeConfirmation })
    }

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <StyledRenewalDialogContainer>
            <SubSectionHeadline data-testid="offers-headline">Choose a Rate Plan</SubSectionHeadline>
            {selectedAccount.termEndDate ? (
                <p data-testid="offers-message-1">Your current rate expires {formatDate(selectedAccount?.termEndDate)}.</p>
            ) : (
                <p data-testid="offers-message-1">You are on a variable rate plan that does not expire.</p>
            )}
            {offers.every(o => o.primaryProduct.productType === ProductTypeCode.Variable) && (
                <p data-testid="offers-message-2">
                    Certain rates are not available in all locations. For further information regarding availability, please call{' '}
                    <PhoneNumberLink phoneNumber={company.phoneNumber.customerSupport} />
                </p>
            )}
            <OfferListView offers={offers} handleSelectedOffer={handleSelectedOffer} showHeroOnly={false} />
        </StyledRenewalDialogContainer>
    )
}

interface RateChangeOffersProps {
    readonly selectedAccount: UserProfileAccount
}
