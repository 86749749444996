import * as React from 'react'
import { FaCaretDown } from 'react-icons/fa'

import { useCombobox } from 'downshift'
import styled, { ThemeProvider } from 'styled-components'

import { FontWeight } from '@igs-web/common-ui-components/styles/font-weight'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { defaultFontSize, fontSizeLarge, fontSizeMedium, lightDark, lightLight } from '@igs-web/common-ui-components/styles/theme'
import { defaultTheme } from '@igs-web/common-ui-components/styles/theming/default-theme'
import { Elevation } from '@igs/react'

const typeaheadHeight = '30vh'

const TypeaheadContainer = styled.div`
    text-align: left;
    position: relative;
    width: 100%;
    display: grid;
    --default-font-size: ${fontSizeMedium};
`

const ComboBox = styled.div`
    display: grid;
    box-sizing: border-box;
    grid-template-columns: 1fr min-content;
    input {
        font-size: ${defaultFontSize};
        grid-column: 1 / -1;
        grid-row: 1;
        height: 3rem;
        padding-left: ${Spacing.Medium};
        padding-right: ${Spacing.ExtraLarge};
        &::placeholder {
            font-size: ${fontSizeLarge};
        }
    }
`

const DropDownButton = styled.button`
    grid-row: 1;
    grid-column: 2;
    border: none;
    z-index: 1;
    font-size: ${defaultFontSize};
`
const MenuWrapper = styled.div`
    position: relative;
`

const Menu = styled.ul`
    margin: 0;
    background: ${lightLight};
    position: absolute;
    box-shadow: ${Elevation.low};
    cursor: pointer;
    width: calc(100% - ${Spacing.ExtraSmall});
    max-height: ${typeaheadHeight};
    overflow-y: auto;
    z-index: 2;
    padding-inline-start: 0;
    :empty {
        display: none;
    }
`

const MenuItem = styled.li<StyledLiProps>`
    background-color: ${({ isActive }) => (isActive ? lightDark : lightLight)};
    font-weight: ${({ isSelected }) => (isSelected ? FontWeight.Bold : FontWeight.Default)};
    font-size: ${defaultFontSize};
    list-style-type: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: ${Spacing.Small};
    overflow: hidden;
`

interface StyledLiProps {
    readonly isActive: boolean
    readonly isSelected: boolean
}

export const TypeaheadSelect = <T extends object>({ selectedItem, items, onSelect, itemToString, filter, label, dataTestId }: Props<T>) => {
    const [stateItems, setStateItems] = React.useState(items)
    const [lastDefaultItem, setLastDefaultItem] = React.useState<T | null>(null)

    const {
        isOpen,
        getToggleButtonProps,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        highlightedIndex,
        getItemProps,
        selectItem,
        setInputValue,
    } = useCombobox<T | null>({
        items: stateItems.slice(), //downshift wants a mutable array
        itemToString,
        onInputValueChange: ({ inputValue }) => {
            if (!inputValue) {
                setStateItems(items)
            } else {
                setStateItems(items.filter(item => filter(item, inputValue)))
            }
        },
        initialSelectedItem: selectedItem,
        onSelectedItemChange: changes => {
            if (changes.selectedItem) {
                onSelect(changes.selectedItem)
            }
        },
    })

    React.useEffect(() => {
        if (selectedItem !== lastDefaultItem) {
            setInputValue(itemToString(selectedItem))
            setLastDefaultItem(selectedItem)
        }
    }, [itemToString, lastDefaultItem, selectedItem, setInputValue])

    return (
        <ThemeProvider theme={defaultTheme}>
            <TypeaheadContainer>
                <ComboBox {...getComboboxProps()}>
                    <input data-testid={`${dataTestId}-input`} placeholder={label} {...getInputProps()} />
                    <DropDownButton
                        data-testid={`${dataTestId}-button`}
                        type="button"
                        {...getToggleButtonProps({ onClick: () => selectItem(null) })}
                        aria-label="toggle menu"
                    >
                        <FaCaretDown />
                    </DropDownButton>
                </ComboBox>
                <MenuWrapper>
                    <Menu data-testid={`${dataTestId}-menu`} {...getMenuProps()}>
                        {isOpen &&
                            stateItems.map((item, index) => (
                                <MenuItem
                                    data-testid={`${dataTestId}-menu-item-${index}`}
                                    style={highlightedIndex === index ? { backgroundColor: '#bde4ff' } : {}}
                                    key={`${itemToString(item)}${index}`}
                                    {...getItemProps({ item, index })}
                                >
                                    {itemToString(item)}
                                </MenuItem>
                            ))}
                    </Menu>
                </MenuWrapper>
            </TypeaheadContainer>
        </ThemeProvider>
    )
}
interface Props<T> {
    readonly items: ReadonlyArray<T>
    readonly selectedItem: T | null
    readonly onSelect: (item: T) => void
    readonly itemToString: (item: T | null) => string
    readonly filter: (item: T, inputValue: string) => boolean
    readonly label: string
    readonly dataTestId: string
}
