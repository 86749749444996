import { HttpCode } from '@igs-web/common-models/constants/http-response-code-type'
import { IgsComError, publicWebsiteError } from '@igs-web/common-models/models/public-website-errors'
import { ApiResponse } from '@igs-web/common-models/models/responses/api-response'

import { pushLoginEventToGa } from '../services/google-analytics-services'
import { loadingManager } from '../utilities/loading-manager-utilities'
import { clearAllFromSessionStorage, clearFromStorage, getFromStorage, putInStorage } from '../utilities/storage-utilities'

import { cache } from './cache'

/* eslint-disable functional/prefer-readonly-type */
export interface RequestConfig {
    readonly throwErrorObject?: boolean
    readonly throwErrorCode?: boolean
    readonly showGlobalLoader: boolean
    readonly ignoreErrors?: boolean
    readonly overrideNonUserPresentableErrors?: string
    readonly overrideAllErrors?: string
}

declare global {
    interface Window {
        // eslint-disable-next-line functional/prefer-readonly-type
        IgsApiClient?: any
    }
}

const authTokenKey = 'auth-token'

class ApiClient {
    public apiBaseUrl: string
    public cacheBuster: string
    public cdnUrl: string
    public environment: string
    public sitefinityApiUrl: string
    public defaultCampaign: string

    public readonly getAssetPath = (path: string) => `${apiClient.cdnUrl}assets/${path}?c=${apiClient.cacheBuster}`
    public readonly getDocPath = (path: string) => `${apiClient.cdnUrl}docs/${path}?c=${apiClient.cacheBuster}`

    public readonly makeRequest = async (url: string, request?: RequestInit | null, config?: RequestConfig | null) => {
        config = config || { showGlobalLoader: true }
        request = request || {}
        let response

        if (config.showGlobalLoader) {
            loadingManager.addLoader()
        }

        const token = this.getToken()

        if (token && url.includes(this.apiBaseUrl)) {
            request.headers = {
                ...request.headers,
                Authorization: `Bearer ${token}`,
            }
        }

        try {
            response = await fetch(url, request)
        } catch (e) {
            if (this.isConnectionError(e)) {
                this.throwError(true, 'Unable to connect to server', config)
            } else {
                this.throwError(false, e.exceptionMessage || e.message, config)
            }
        } finally {
            if (config.showGlobalLoader) {
                loadingManager.removeLoader()
            }
        }

        if (!response.ok) {
            if (response.status === HttpCode.FORBIDDEN) {
                this.logout({ showGlobalLoader: false })
            }

            const error = await this.getError(response)
            this.throwError(error.isMessageUserPresentable, error.message, config, error.errorCode)
        }

        return response
    }

    private readonly isConnectionError = (e: Error): boolean => {
        return e && e.message === 'Failed to fetch'
    }

    private readonly throwError = (isMessageUserPresentable: boolean, message: string, config: RequestConfig, errorCode: string | null = null) => {
        if (config.overrideAllErrors) {
            throw Error(config.overrideAllErrors)
        } else if (config.overrideNonUserPresentableErrors && !isMessageUserPresentable) {
            throw Error(config.overrideNonUserPresentableErrors || 'An error has occurred')
        } else if (config.throwErrorCode && errorCode) {
            throw Error(errorCode)
        } else {
            throw Error(message)
        }
    }

    private readonly testAuthentication = async <T>(fn: () => Promise<T | null>): Promise<T | null> => {
        const token = this.getToken()
        if (!token) {
            console.log('No Token Found')
            await this.logout({ showGlobalLoader: false })
            return Promise.resolve(null)
        } else {
            return fn()
        }
    }

    public readonly get = async <T>(url, config?: RequestConfig | null) => {
        const response = await this.makeRequest(url, null, config)
        return response ? ((await response.json()) as T) : null
    }

    public readonly getAuthed = async <T>(url, config?: RequestConfig | null) => {
        return this.testAuthentication(() => {
            return this.get<T>(url, config)
        })
    }

    public readonly cachedGet = async <T>(url, key, ignoreCache, config?: RequestConfig | null) => {
        const requestKey = key + '-request'
        const cachedUrl = await cache(requestKey, () => url)()
        const shouldIgnorCache = ignoreCache || cachedUrl !== url
        if (shouldIgnorCache) {
            cache(requestKey, () => url, true)()
        }
        return cache(
            key,
            async () => {
                return (await apiClient.get(url, config)) as T
            },
            shouldIgnorCache,
        )()
    }

    public readonly cachedGetAuthed = async <T>(url, key, ignoreCache, config?: RequestConfig | null) => {
        return this.testAuthentication(() => {
            return this.cachedGet<T>(url, key, ignoreCache, config)
        })
    }

    public readonly getBlob = async (url, config?: RequestConfig | null) => {
        const response = await this.makeRequest(url, null, config)
        return response ? await response.blob() : await null
    }

    public readonly getBlobAuthed = async (url, config?: RequestConfig | null) => {
        return this.testAuthentication(() => {
            return this.getBlob(url, config)
        })
    }

    public readonly postBlob = async (url, body?: any, config?: RequestConfig | null) => {
        const request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        }

        const response = await this.makeRequest(url, request, config)
        return response ? await response.blob() : await null
    }
    public readonly postBlobAuthed = async (url, body?: any, config?: RequestConfig | null) => {
        return this.testAuthentication(() => {
            return this.postBlob(url, body, config)
        })
    }

    public readonly post = async <T>(url: string, body?: any, config?: RequestConfig | null) => {
        const request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        }
        const response = await this.makeRequest(url, request, config)
        return response && response.status !== HttpCode.NO_CONTENT ? ((await response.json()) as T) : null
    }

    public readonly postAuthed = async <T>(url: string, body?: any, config?: RequestConfig | null) => {
        return this.testAuthentication(() => {
            return this.post<T>(url, body, config)
        })
    }

    public readonly postFormData = async <T>(url: string, body?: any, config?: RequestConfig | null) => {
        body = body || {}
        const formParams = new FormData()
        for (const key of Object.keys(body)) {
            const value = body[key]

            if (Array.isArray(value)) {
                value.forEach(val => {
                    formParams.append(key, val)
                })
            }
            formParams.append(key, value)
        }
        const request = {
            method: 'POST',
            body: formParams,
        }
        const response = await this.makeRequest(url, request, config)
        return response && response.status !== HttpCode.NO_CONTENT ? ((await response.json()) as T) : await null
    }
    postFormDataAuthed = async <T>(url: string, body?: any, config?: RequestConfig | null) => {
        return this.testAuthentication(() => {
            return this.postFormData<T>(url, body, config)
        })
    }

    public readonly put = async <T>(url: string, body?: any, config?: RequestConfig | null) => {
        const request = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        }
        const response = await this.makeRequest(url, request, config)
        return response && response.status !== HttpCode.NO_CONTENT ? ((await response.json()) as T) : await null
    }

    public readonly putAuthed = async <T>(url: string, body?: any, config?: RequestConfig | null) => {
        return this.testAuthentication(() => this.put<T>(url, body, config))
    }

    public readonly login = async (username: string, password: string, config?: RequestConfig) => {
        const loginResponse = await this.post<ApiResponse<string>>(`${this.apiBaseUrl}/login`, { username, password }, config)
        if (!loginResponse || !loginResponse.data) {
            throw Error('Login Failed')
        }
        this.setUserToken(loginResponse.data)
        const storedToken = this.getToken()
        if (!storedToken) {
            throw Error('Please enable cookies to continue')
        }
        pushLoginEventToGa()
    }

    public readonly logout = async (config?: RequestConfig) => {
        const token = this.getToken()
        try {
            if (token) {
                clearFromStorage(authTokenKey, true)
                await this.post<string>(`${this.apiBaseUrl}/logout`, { token }, config)
            }
        } catch (e) {
            console.error(`unable to invalidate token: ${e.message}`)
        } finally {
            clearAllFromSessionStorage()
        }
    }

    public readonly clearToken = () => {
        clearFromStorage(authTokenKey, true)
    }

    public readonly hasToken = () => {
        return !!this.getToken()
    }

    private readonly getToken = () => {
        return getFromStorage(authTokenKey, true)
    }

    public readonly setUserToken = (token: string) => putInStorage(token, authTokenKey, true)

    public readonly isApiAndCrmAvailable = async (config: RequestConfig) => {
        try {
            return await this.get<boolean>(`${this.apiBaseUrl}/crm/healthcheck`, config)
        } catch (e) {
            return false
        }
    }

    private readonly getError = async (response: Response): Promise<IgsComError> => {
        switch (response.status) {
            case HttpCode.BAD_REQUEST:
                return this.get400Error(response)
            default:
                return this.get500Error(response)
        }
    }

    private readonly get400Error = async (response: Response): Promise<IgsComError> => {
        const igsComError = (await response.json()) as IgsComError
        return igsComError
    }

    private readonly get500Error = async (response: Response): Promise<IgsComError> => {
        const json = (await response.json()) as IgsComError & { readonly exceptionMessage?: string }

        const pubWebError = publicWebsiteError(
            json ? json.exceptionMessage || json.message || '' : response.statusText,
            json && json.isMessageUserPresentable,
            json && json.errorCode,
        )

        return pubWebError
    }
}

if (!window.IgsApiClient) {
    window.IgsApiClient = new ApiClient()
}

export const apiClient: ApiClient = window.IgsApiClient
