import * as React from 'react'

import { ChoiceInvoice, ChoicePayment } from '@igs-web/common-models/models/choice-transaction-history'

import { useCompany } from '../company/hooks/useCompany'
import { BillHistoryTable } from './bill-history-table'
import { PaymentHistoryChart } from './payment-history-chart'
import { PaymentHistoryTable } from './payment-history-table'

export const TransactionHistory = ({ accountId, invoices, payments, isPaymentHistory }: OwnProps): JSX.Element => {
    const { features } = useCompany()

    return (
        <>
            {!isPaymentHistory && <BillHistoryTable invoices={invoices} accountId={accountId} />}
            {isPaymentHistory && features.displayPaymentHistoryChart && <PaymentHistoryChart payments={payments} />}
            {isPaymentHistory && <PaymentHistoryTable payments={payments} accountId={accountId} />}
        </>
    )
}

interface OwnProps {
    readonly accountId: number
    readonly payments: ReadonlyArray<ChoicePayment>
    readonly invoices: ReadonlyArray<ChoiceInvoice>
    readonly isPaymentHistory: boolean
}
