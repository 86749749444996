import * as React from 'react'

import styled from 'styled-components'

import { SummaryBody } from '@igs-web/common-components/domain/checkout/summaries/summary-wrapper'
import { BaseCompany } from '@igs-web/common-components/domain/company/company'
import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import {
    Contact,
    HomeWarrantyOneTimePaymentRequest,
    HomeWarrantyOneTimePaymentResponse,
    HomeWarrantyPaymentInformation,
    PaymentFrequency,
    creditCardId,
    emptyHomeWarrantyPaymentInformation,
} from '@igs-web/common-models/models'
import { IconHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { StrongText } from '@igs-web/common-ui-components/_atoms/typography'
import { AddressDisplay } from '@igs-web/common-ui-components/_molecules/address/address-display'
import { GridSpacer, commonGridCss } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { InfoText, InfoTextType } from '@igs-web/common-ui-components/_molecules/info-text'
import { LocalLoader } from '@igs-web/common-ui-components/_molecules/loading-indicator'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { borderBoxColor } from '@igs-web/common-ui-components/styles/theme'
import { formatPhoneNumberForDisplay } from '@igs-web/common-utilities/utilities/phone-utilities'
import { getUrlParameter } from '@igs-web/common-utilities/utilities/query-string-utilities'

import { homeWarrantyOneTimePaymentApiClient } from 'root/shared-services/api-clients/home-warranty-one-time-payment-api-client'

import { NavigationBanner } from 'molecules/header/navigation-banner'

import { PaymentForm, PaymentFormData } from './payment-form'

export enum PaymentCaptureDuration {
    OneTime = 'onetime',
    Recurring = 'enrollment',
}

const PaymentGrid = styled.div`
    ${commonGridCss}
    grid-template-columns:1fr 1fr;
    grid-template-areas: 'name address' 'spacer spacer' 'pmtHeader addHeader' 'pmtAmount addToggle' 'pmtForm addForm' 'button nil';
    ${SummaryBody} {
        border-color: ${borderBoxColor};
        margin-inline-start: 0;
        margin-block-start: ${Spacing.Medium};
    }
    align-items: start;
    .name-display {
        grid-area: name;
    }
    .address-display {
        grid-area: address;
    }
    .header-separator {
        grid-area: spacer;
        @media (max-width: ${Breakpoint.Tablet}) {
            display: none;
        }
    }
    .payment-form-header {
        grid-area: pmtHeader;
    }
    .payment-form-amount {
        grid-area: pmtAmount;
    }
    .payment-form-capture {
        grid-area: pmtForm;
    }
    .address-form-header {
        grid-area: addHeader;
    }
    .address-form-toggle {
        grid-area: addToggle;
    }
    .address-form-capture {
        grid-area: addForm;
    }
    .form-button {
        grid-area: button;
    }
    @media (max-width: ${Breakpoint.Tablet}) {
        grid-template-areas: 'name' 'address' 'pmtHeader' 'pmtAmount' 'pmtForm' 'addHeader' 'addToggle' 'addForm' 'button';
        padding-inline: ${Spacing.Small};
    }
`

const LoadingErrorDisplay = ({ title, company }: ErrorProps) => (
    <>
        <NavigationBanner title={title} />
        <GridSpacer spacing={Spacing.Large} />
        <InfoText infoTextType={InfoTextType.Error}>
            <p>An error occured while trying to retrieve payment details. We sincerely apologize for the inconvenience.</p>
            <p>
                Please give us a call {company.businessHours.businessHoursAbbreviated} at
                <PhoneNumberLink phoneNumber={company.phoneNumber.help} />
            </p>
        </InfoText>
        <GridSpacer spacing={Spacing.Large} />
    </>
)

interface ErrorProps {
    readonly title: string
    readonly company: BaseCompany
}

const PaymentFormDisplay = ({ title, paymentDetail, frequency, submit, errorResponse }: FormDisplayProps, loaded) => {
    const company = useCompany()
    return (
        <>
            <NavigationBanner title={title} />
            <GridSpacer spacing={Spacing.Large} />
            {errorResponse && !errorResponse?.successful && (
                <>
                    <InfoText infoTextType={InfoTextType.Error}>
                        Something went wrong while processing your request. Please call us {company.businessHours.homwWarrantyAbbreviated} at{' '}
                        <PhoneNumberLink phoneNumber={company.phoneNumber.homeWarranty} />.
                    </InfoText>
                    <GridSpacer spacing={Spacing.Large} />
                </>
            )}
            {loaded ? (
                <PaymentGrid>
                    <div className="name-display">
                        <IconHeading>Customer Name</IconHeading>
                        <SummaryBody>
                            {paymentDetail.firstName} {paymentDetail.lastName}
                            <br />
                            {formatPhoneNumberForDisplay(paymentDetail.phoneNumber)}
                        </SummaryBody>
                    </div>
                    <div className="address-display">
                        <IconHeading>Service Address</IconHeading>
                        <SummaryBody>
                            <AddressDisplay address={paymentDetail.serviceAddress} />
                        </SummaryBody>
                    </div>
                    <GridSpacer className="header-separator" spacing={Spacing.Medium} />
                    <PaymentForm amount={paymentDetail.amount} frequency={frequency} submit={submit} />
                </PaymentGrid>
            ) : (
                <LocalLoader isLoading={true} />
            )}
            <GridSpacer spacing={Spacing.Large} />
        </>
    )
}

interface FormDisplayProps {
    readonly title: string
    readonly paymentDetail: HomeWarrantyPaymentInformation
    readonly frequency: PaymentCaptureDuration
    readonly submit: (data: PaymentFormData) => void
    readonly loaded: boolean
    readonly errorResponse?: HomeWarrantyOneTimePaymentResponse | null
}

const ConfirmationDisplay = ({ title, frequency, confirmation }: ConfirmationDisplayProps) => {
    return (
        <>
            <NavigationBanner title={title} />
            <GridSpacer spacing={Spacing.Large} />
            {frequency === PaymentCaptureDuration.OneTime && (
                <>
                    <StrongText>Thank you for submitting your payment.</StrongText>
                    <p>We have received your one-time payment.</p>
                </>
            )}

            {frequency === PaymentCaptureDuration.Recurring && (
                <>
                    <StrongText>Thank you for setting up recurring payments.</StrongText>
                    <p>We have received your information.</p>
                </>
            )}
            <StrongText>
                Confirmation Number:{' '}
                {frequency === PaymentCaptureDuration.OneTime ? (
                    <span data-testid="one-time-payment-confirmation">{confirmation}</span>
                ) : (
                    <>
                        <span data-testid="recurring-payment-confirmation">{confirmation}</span> - Recurring
                    </>
                )}
            </StrongText>

            <GridSpacer spacing={Spacing.Large} />
        </>
    )
}

interface ConfirmationDisplayProps {
    readonly title: string
    readonly confirmation: string
    readonly frequency: PaymentCaptureDuration
}

const buildRequest = (
    token: string,
    frequency: PaymentCaptureDuration,
    detail: HomeWarrantyPaymentInformation,
    paymentInfo: PaymentFormData,
): HomeWarrantyOneTimePaymentRequest => {
    const billingAddress = paymentInfo.isBillingSameAsServiceAddress || !paymentInfo.billingAddress ? detail.serviceAddress : paymentInfo.billingAddress

    const accountHolder: Contact = {
        firstName: detail.firstName,
        lastName: detail.lastName,
        businessName: detail.merchantName,
        phoneNumber: detail.phoneNumber,
        middleInitial: null,
        emailAddress: null,
    }

    const base = {
        amount: detail.amount,
        token,
        serviceAddress: detail.serviceAddress,
        isBillingSameAsServiceAddress: paymentInfo.isBillingSameAsServiceAddress,
        billingAddress,
        paymentOptionId: creditCardId,
        payment: paymentInfo.payment,
    }

    if (frequency === PaymentCaptureDuration.OneTime) {
        return {
            ...base,
            accountHolder,
            saveAsRecurringPayment: false,
        }
    }

    return {
        ...base,
        orderNumber: detail.orderNumber,
        accountHolder,
        hasPaymentAuthorizationConsent: paymentInfo.acceptedTerms,
        paymentFrequency: PaymentFrequency.Monthly,
    }
}

export const InboundPaymentPage = ({ frequency }: Props) => {
    const title = frequency === PaymentCaptureDuration.OneTime ? 'One-Time Payment' : 'Recurring Payment'
    const [paymentDetail, setPaymentDetail] = React.useState<HomeWarrantyPaymentInformation>(emptyHomeWarrantyPaymentInformation())
    const [loaded, setLoaded] = React.useState(false)
    const [loadingError, setLoadingError] = React.useState(false)
    const company = useCompany()
    const [paymentResponse, setPaymentResponse] = React.useState<HomeWarrantyOneTimePaymentResponse | null>(null)
    const token = getUrlParameter('token')

    const submit = async (paymentInfo: PaymentFormData) => {
        const request = buildRequest(token, frequency, paymentDetail, paymentInfo)
        const response = await homeWarrantyOneTimePaymentApiClient.submitPayment(request, frequency)
        setPaymentResponse(response)
        window.scrollTo(0, 0)
    }

    React.useEffect(() => {
        const isGuidValid = async () => {
            try {
                const response = await homeWarrantyOneTimePaymentApiClient.getAmountForPayment(token, frequency, { showGlobalLoader: false })
                if (response) {
                    if (response.errorCode) {
                        setLoadingError(true)
                    } else {
                        setLoadingError(false)
                        setPaymentDetail(response)
                        setLoaded(true)
                    }
                } else {
                    setLoadingError(true)
                }
            } catch (e) {
                setLoadingError(true)
            }
        }
        isGuidValid()
    }, [token, frequency])

    if (loadingError) {
        return <LoadingErrorDisplay title={title} company={company} />
    }

    if (paymentResponse && paymentResponse.successful) {
        return <ConfirmationDisplay title={title} frequency={frequency} confirmation={paymentResponse.message} />
    }

    return (
        <PaymentFormDisplay errorResponse={paymentResponse} title={title} frequency={frequency} paymentDetail={paymentDetail} loaded={loaded} submit={submit} />
    )
}

interface Props {
    readonly frequency: PaymentCaptureDuration
}
