import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import styled from 'styled-components'

import { homeWarrantyPaymentProfileRedux } from '@igs-web/common-components/domain/payment-profile/hw-payment-profile-redux'
import { UserSelectors } from '@igs-web/common-components/domain/user/user-redux'
import { UserProfile } from '@igs-web/common-models/models'
import { ButtonStyleType, mapToStyle } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { BoxedErrorAlert, StyledSuccessAlert } from '@igs-web/common-ui-components/_atoms/forms/alert'
import { IconHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'
import { ErrorText, StrongText } from '@igs-web/common-ui-components/_atoms/typography'
import { GridSpacer, commonGridCss } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { InfoText } from '@igs-web/common-ui-components/_molecules/info-text'
import { PageContentContainer } from '@igs-web/common-ui-components/_molecules/page-content-container'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { bodyColor } from '@igs-web/common-ui-components/styles/theme'

import { NavigationBanner } from 'molecules/header/navigation-banner'
import { ScrollToTop } from 'molecules/routing/scroll-to-top'

import { useHwMyaccountState } from 'domain/home-warranty/hw-myaccount-context'
import { HwMyAccountAddressSelector } from 'domain/home-warranty/hw-service-address-selector'
import { getSelectedHwAccount } from 'domain/home-warranty/scana-hw-service'
import * as Routes from 'domain/routes'

import { HWCurrentPaymentMethod } from './hw-current-payment'
import { UpdatePaymentForm } from './update-payment-form'

const UpdatePaymentLayout = styled.div`
    --header-color: ${bodyColor};
    ${commonGridCss}
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        'address address'
        'products payments'
        'form form';
    @media (max-width: ${Breakpoint.Tablet}) {
        padding-inline: ${Spacing.Small};
        grid-template-areas:
            'address'
            'products'
            'payments'
            'form';
        ${BoxedErrorAlert} {
            grid-column: span 1;
        }
        ${StyledSuccessAlert} {
            grid-column: span 1;
        }
        > .btn {
            grid-column: span 1;
        }
    }
    .noPlanDisplay {
        grid-column: span 2;
    }
    ${BoxedErrorAlert} {
        grid-column: span 2;
        padding: ${Spacing.Medium};
        text-align: center;
    }
    ${StyledSuccessAlert} {
        grid-column: span 2;
        padding: ${Spacing.Medium};
        text-align: center;
    }
    ${IconHeading} {
        padding-block-start: ${Spacing.Large};
    }
    p:not${ErrorText} {
        margin: 0;
    }
    > .btn {
        grid-column: span 2;
        justify-self: right;
    }
    .product-display {
        grid-area: products;
    }
    .current-payment-method {
        grid-area: payments;
    }
    .address {
        grid-area: address;
    }
`

const NoPlanDisplay = () => (
    <UpdatePaymentLayout>
        <GridSpacer spacing={Spacing.Medium} />
        <div className="noPlanDisplay">You do not currently have a Home Warranty plan. </div>
    </UpdatePaymentLayout>
)

const NoHomeWarrantyAtAddress = () => (
    <UpdatePaymentLayout>
        <HwMyAccountAddressSelector
            className="address"
            headerText="Service Address"
            dropdownHeaderText="Please select the service address you'd like to update payment for:"
        />
        <BoxedErrorAlert message={<StrongText>There are no accounts with bill payment options at this address</StrongText>} />
    </UpdatePaymentLayout>
)

const PaymentUpdated = () => (
    <UpdatePaymentLayout>
        <HwMyAccountAddressSelector
            className="address"
            headerText="Service Address"
            dropdownHeaderText="Please select the service address you'd like to update payment for:"
        />
        <StyledSuccessAlert message={<StrongText>Your payment method has been updated.</StrongText>} />
        <Link className={`btn ${mapToStyle(ButtonStyleType.Primary)}`} to={`${Routes.HwAccountSummary}`}>
            To Account Summary
        </Link>
    </UpdatePaymentLayout>
)

const UpdatePaymentWrapper = ({ userProfile }: UpdatePaymentWrapperProps) => {
    const dispatch = useDispatch()
    const { selectedAddressHash } = useHwMyaccountState()
    const [paymentUpdated, setPaymentUpdated] = React.useState(false)
    const selectedHWAccount = getSelectedHwAccount(userProfile, selectedAddressHash)

    if (!selectedAddressHash) {
        return <NoPlanDisplay />
    }

    if (!selectedHWAccount) {
        return <NoHomeWarrantyAtAddress />
    }

    const onSuccess = (accountId: number) => {
        setPaymentUpdated(true)
        dispatch(homeWarrantyPaymentProfileRedux.actions.clearAll())
        dispatch(homeWarrantyPaymentProfileRedux.actions.load({ key: accountId, showGlobalLoader: false, ignoreCache: true }))
    }

    if (paymentUpdated) {
        return <PaymentUpdated />
    }
    return (
        <UpdatePaymentLayout>
            <HwMyAccountAddressSelector
                className="address"
                headerText="Service Address"
                dropdownHeaderText="Please select the service address you'd like to update payment for:"
            />
            <div className="product-display">
                <IconHeading>Product</IconHeading>
                <GridSpacer spacing={Spacing.Large} />
                <p>{selectedHWAccount.protectionPlan}</p>
            </div>
            <div className="current-payment-method">
                <IconHeading>Current Payment Method</IconHeading>
                <GridSpacer spacing={Spacing.Large} />
                <HWCurrentPaymentMethod accountId={selectedHWAccount.accountId} />
            </div>
            <div style={{ gridArea: 'form' }}>
                <IconHeading>New Payment Method</IconHeading>
                <UpdatePaymentForm accountId={selectedHWAccount.accountId} onSuccess={onSuccess} />
            </div>
        </UpdatePaymentLayout>
    )
}
interface UpdatePaymentWrapperProps {
    readonly userProfile: UserProfile
}

export const UpdatePaymentPage = () => {
    const userProfile = useSelector(UserSelectors.selectProfile)

    if (!userProfile) {
        return <div>Error: no userprofile</div>
    }

    const billingAccount = userProfile.billingAccounts.length > 0 ? userProfile.billingAccounts[0] : null

    return (
        <>
            <ScrollToTop />
            <NavigationBanner title="Home Warranty - Manage Payment" />
            <PageContentContainer>
                {billingAccount && (
                    <>
                        <GridSpacer spacing={Spacing.Large} />
                        <InfoText>
                            Note: Any changes made on this page will only apply to your home warranty product. Your natural gas product will not be affected. If
                            you need to update your payment method for natural gas, please visit <Link to={Routes.PayBill}>Ways To Pay</Link>.
                        </InfoText>
                    </>
                )}
                <UpdatePaymentWrapper userProfile={userProfile} />

                <GridSpacer spacing={Spacing.Large} />
            </PageContentContainer>
        </>
    )
}
