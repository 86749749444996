import { homeUrl } from '@igs-web/common-components/domain/general-routes'
import { MembershipApplication } from '@igs-web/common-models/constants/membership-application'
import { apiClient } from '@igs-web/common-utilities/api/api-client'

import { BaseCompany, SupportedCompanies } from '../company'
import { defaultCompanyFeatures } from '../companyFeatures'
import { defaultCampaignCodes, defaultEnrollmentFeatures } from '../enrollmentFeatures'
import { defaultRouteFeatures } from '../routeFeatures'

import { igsVerbiage } from './igs-verbiage'

export const igsCompany = (): BaseCompany => ({
    name: SupportedCompanies.Igs,
    displayName: 'IGS Energy',
    displayNameByLob: () => 'IGS Energy',
    myAccountUrl: homeUrl().asString(),
    externalWebsite: 'https://www.igs.com',
    membershipApplicationName: MembershipApplication.Igs,
    address: '6100 Emerald Parkway, Dublin, OH 43016',
    businessHours: {
        businessHoursFull: 'Mon-Fri 8am-8pm | Sat 9am-3pm EST',
        businessHoursAbbreviated: 'Mon-Fri 8am-8pm | Sat 9am-3pm EST',
        businessHoursAbbreviatedWeek: 'Mon-Fri 8am-8pm',
        businessHoursAbbreviatedWeekend: 'Sat 9am-3pm EST',
        homwWarrantyAbbreviated: 'Mon-Fri 8am-8pm | Sat 9am-3pm EST',
        homeWarrantyFull: 'Mon-Fri 8am-8pm | Sat 9am-3pm EST',
    },
    emails: {
        customerSupport: 'customersupport@igs.com',
        commecialSupport: 'EnergyAdvisor@igs.com',
        communitySolar: 'CommunitySolar@igs.com',
        solarSupport: 'solarsupport@igssolarpower.com',
        homeWarranty: 'customersupport@igs.com',
    },
    phoneNumber: {
        help: '18889950992',
        gasLeak: '',
        heatProgram: '',
        customerSupport: '8779954447',
        commecialSupport: '8777712577',
        communitySolar: '8884419744',
        solarSupport: '8889740114',
        homeWarranty: '',
        supportFax: '8879954447',
    },
    verbiage: { ...igsVerbiage },
    graphics: {
        colorLogo: apiClient.getAssetPath('logos/igs-energy-stacked-rgb.svg'),
        monochromeLogo: apiClient.getAssetPath('logos/igs-energy-stacked-bw.svg'),
        loginBackground: apiClient.getAssetPath('login-bg.jpg'),
    },
    features: {
        ...defaultCompanyFeatures,
    },
    includeNonBreakingHyphenInTermMonths: true,
    includeLobTitleInProductTitle: true,
    useCompanyCommodityTitle: false,
    enrollment: {
        ...defaultEnrollmentFeatures,
        showPaperlessOption: true,
        campaignCodes: {
            ...defaultCampaignCodes,
            renewalDefault: 'Web-MyAccount-IGS',
        },
    },
    routes: {
        ...defaultRouteFeatures,
    },
})
