import * as React from 'react'

import _sortBy from 'lodash-es/sortBy'

import { LegacyUsage } from '@igs-web/common-models/models/legacy-data'
import { Usage } from '@igs-web/common-models/models/usage'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'
import { formatAsMoney } from '@igs-web/common-utilities/utilities/currency-utilities'
import { downloadBlob } from '@igs-web/common-utilities/utilities/document-utilities'

import { formatWithComma } from 'root/shared-services/number-utilites'

import { BaseCompany } from '../company/company'
import { CompanyVerbiage, Verbiage } from '../company/companyVerbiage'
import { useCompany } from '../company/hooks/useCompany'
import { DefaultEmptyTable } from '../data-table/default-empty-table'
import { DateFilter } from '../data-table/filters/date-filter'
import { TableColumn } from '../data-table/models/table-column'
import { TableDataType } from '../data-table/models/table-data-types'
import { LegacyInvoicePdfLink } from '../myaccount/legacy-data/legacy-invoice-pdf-link'

import { CommodityAccountTable } from './commodity-account-table'

interface UsageTableRecord {
    readonly fromDate?: number
    readonly toDate: number
    readonly meterReadType: React.ReactNode
    readonly quantityDisplay: string
    readonly chargesPerUnit: React.ReactNode
    readonly commodityChargeDisplay: string
}
//TODO: When usage data becomes better revert magic number 2 to use company.feature.rateFormatDecimalPlaces/rateFormatMoneyPrecision
const mapToTableRecord = (usage: Usage, company: BaseCompany): UsageTableRecord => ({
    fromDate: usage.fromDate,
    toDate: usage.toDate,
    meterReadType: usage.meterReadType,
    quantityDisplay: `${usage.quantity ? formatWithComma(usage.quantity) : 0} ${usage.unitOfMeasure}`,
    chargesPerUnit:
        usage.quantity && usage.perUnitCharge ? `${formatAsMoney(usage.perUnitCharge, company.features.variablRateMoneyFormat, false, 2, 2)}` : 'N/A',
    commodityChargeDisplay: usage.quantity && usage.commodityCharge ? formatAsMoney(usage.commodityCharge) : 'N/A',
})

const mapLegacyToTableRecord = (usage: LegacyUsage): UsageTableRecord => ({
    ...usage,
    quantityDisplay: `${usage.quantity ? formatWithComma(usage.quantity) : 0} ${usage.unitOfMeasure}`,
    meterReadType: <LegacyInvoicePdfLink invoiceUrl={usage.invoiceUrl} label={usage.meterReadType} />,
    chargesPerUnit: <LegacyInvoicePdfLink invoiceUrl={usage.invoiceUrl} label={usage.chargesPerUnit} />,
})

const formatData = (usages: ReadonlyArray<Usage>, company: BaseCompany): ReadonlyArray<UsageTableRecord> => {
    const records = usages.map(usage => mapToTableRecord(usage, company))
    return _sortBy(records, r => r.fromDate).reverse()
}

const getColumnDefs = (hideCharges: boolean, verbiage: CompanyVerbiage): ReadonlyArray<TableColumn> => [
    { header: 'Beginning Date', accessor: 'fromDate', dataType: TableDataType.DateTime, filter: DateFilter },
    { header: 'End Date', accessor: 'toDate', dataType: TableDataType.DateTime, disableSorting: true },
    { header: 'Meter Reading', accessor: 'meterReadType', dataType: TableDataType.ReactNode, disableSorting: true, hideOnExport: true },
    {
        header: verbiage[Verbiage.usageTableHeadersQuantity],
        accessor: 'quantityDisplay',
        dataType: TableDataType.String,
        disableSorting: true,
        hideOnExport: true,
    },
    {
        header: verbiage[Verbiage.usageTableHeadersChargesPerUnit],
        accessor: 'chargesPerUnit',
        dataType: TableDataType.ReactNode,
        disableSorting: true,
        hideOnExport: true,
    },
    {
        header: 'Commodity Charge',
        accessor: 'commodityChargeDisplay',
        dataType: TableDataType.String,
        disableSorting: true,
        hideColumn: hideCharges,
        hideOnExport: true,
    },
]

const downloadPdf = async (accountId: number) => {
    const blob = await customerInfoApiClient.getUsagePdfByAccountId(accountId)
    downloadBlob(blob, 'usage', 'pdf')
}

const downloadCsv = async (accountId: number) => {
    const blob = await customerInfoApiClient.getUsageCsvByAccountId(accountId)
    downloadBlob(blob, 'usage', 'csv')
}

export const UsageTable = ({ usages, legacyUsages = [], hideCharges, accountId, dataTestId }: UsageTableProps): JSX.Element => {
    const company = useCompany()
    const columnDefs = getColumnDefs(hideCharges, company.verbiage)
    const usageData = formatData(usages, company)

    const legacyUsageData = legacyUsages.map(mapLegacyToTableRecord)

    const rowData = [...usageData, ...legacyUsageData].sort((a, b) => (b.fromDate ?? 0) - (a.fromDate ?? 0))

    const handlePdfDownload = () => downloadPdf(accountId)
    const handleCvsDownload = () => downloadCsv(accountId)

    return (
        <CommodityAccountTable<UsageTableRecord>
            tableTitle={'Usage'}
            columns={columnDefs}
            data={rowData}
            handlePdfDownload={usageData.length ? handlePdfDownload : undefined}
            handleCsvDownload={usageData.length ? handleCvsDownload : undefined}
            showWhenEmpty={<DefaultEmptyTable tableDataType="usages" phoneNumber={company.phoneNumber.customerSupport} />}
            dataTestId={dataTestId}
        />
    )
}
interface UsageTableProps {
    readonly usages: ReadonlyArray<Usage>
    readonly legacyUsages?: ReadonlyArray<LegacyUsage>
    readonly hideCharges: boolean
    readonly accountId: number
    readonly tableDataType?: string
    readonly dataTestId: string
}
