import * as React from 'react'

import { BaseCompany } from '../company'
import { defaultCompanyFeatures } from '../companyFeatures'
import { defaultEnrollmentFeatures } from '../enrollmentFeatures'
import { igsCompany } from '../igs/igs-company'
import { defaultRouteFeatures } from '../routeFeatures'
import { scanaRegulatedCompany } from '../scana-regulated/scana-regulated-company'
import { scanaCompany } from '../scana/scana-company'

export const emptyCompany = (): BaseCompany => ({
    name: '',
    displayName: '',
    displayNameByLob: () => '',
    membershipApplicationName: '',
    phoneNumber: {
        help: '',
        gasLeak: '',
        heatProgram: '',
        customerSupport: '',
        commecialSupport: '',
        communitySolar: '',
        solarSupport: '',
        homeWarranty: '',
        supportFax: '',
    },
    businessHours: {
        businessHoursFull: '',
        businessHoursAbbreviated: '',
        businessHoursAbbreviatedWeek: '',
        businessHoursAbbreviatedWeekend: '',
        homwWarrantyAbbreviated: '',
        homeWarrantyFull: '',
    },
    emails: {
        customerSupport: '',
        commecialSupport: '',
        communitySolar: '',
        solarSupport: '',
        homeWarranty: '',
    },
    address: '',
    myAccountUrl: '',
    externalWebsite: '',
    verbiage: {},
    graphics: {
        colorLogo: '',
        monochromeLogo: '',
        loginBackground: '',
    },
    features: {
        ...defaultCompanyFeatures,
    },
    includeNonBreakingHyphenInTermMonths: true,
    includeLobTitleInProductTitle: true,
    useCompanyCommodityTitle: false,
    enrollment: {
        ...defaultEnrollmentFeatures,
    },
    routes: {
        ...defaultRouteFeatures,
    },
})

// eslint-disable-next-line max-len
//note 12/7/20: long term the below should return emptyCompany if it can't find one in the array, but since we're only using this for igs right now, defaulting to IGS
export const selectCompany = (companyName: string): BaseCompany =>
    [scanaCompany(), igsCompany(), scanaRegulatedCompany()].find(company => company.name.toLowerCase() === companyName.toLowerCase()) || igsCompany()

const initialContext: CompanyContext = emptyCompany()

const CompanyContext = React.createContext<CompanyContext>(initialContext)

export const CompanyProvider = ({ children, companyName }: CompanyProviderProps): JSX.Element => {
    const [companyState] = React.useState<CompanyContext>(selectCompany(companyName))

    return <CompanyContext.Provider value={companyState}>{children}</CompanyContext.Provider>
}

export const useCompany = (): CompanyContext => {
    const context = React.useContext(CompanyContext)
    if (!context) {
        throw Error('No available provider')
    }
    return context
}

export const CompanyWrapperForClassComponents = ({ children }): JSX.Element => {
    const company = useCompany()
    return children(company)
}

interface CompanyProviderProps {
    readonly children: React.ReactNode
    readonly companyName: string
}

export type CompanyContext = BaseCompany
