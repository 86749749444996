/* eslint-disable max-lines */
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { Account, AccountStatus } from '@igs-web/common-models/models/account-model'
import { CommunitySolarAccount } from '@igs-web/common-models/models/community-solar-model'
import { LineOfBusinessCode } from '@igs-web/common-models/models/line-of-business'
import { UserProfile, UserProfileAccount, UserProfileServiceAddress } from '@igs-web/common-models/models/user-profile-model'
import { RequestConfig, apiClient } from '@igs-web/common-utilities/api/api-client'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'
import { CHECKOUT_STORAGE_KEY, HW_CHECKOUT_STORAGE_KEY } from '@igs-web/common-utilities/constants/constants'
import { identifyUserForFullStory, mapToFullStoryIdentity } from '@igs-web/common-utilities/utilities/fs-logger'
import { formatPhoneNumberForStorage } from '@igs-web/common-utilities/utilities/phone-utilities'
import { createAction, reducer } from '@igs-web/common-utilities/utilities/reducer-utilities'
import { clearFromStorage } from '@igs-web/common-utilities/utilities/storage-utilities'

import { enrollmentModelActions } from '../enrollment/enrollment-redux-actions'
import { notificationActions } from '../notification/notification-redux'

export const userActionTypes = {
    LOAD_USER: '[user] LOAD_USER // REQUEST',
    LOAD_USER_SUCCESS: '[user] LOAD_USER // SUCCESS',
    LOAD_USER_FAIL: '[user] LOAD_USER // FAIL',

    RELOAD_USER: '[user] RELOAD_USER // REQUEST',

    LOGIN_SUCCESS: '[auth] LOGIN // SUCCESS',

    LOGOUT: '[auth] LOGOUT // REQUEST',
    LOGOUT_SUCCESS: '[auth] LOGOUT // SUCCESS',

    REQUEST_VERIFICATION: '[auth] REQUEST_VERIFICATION',
    VERIFY_EMAIL_SUCCESS: '[auth] VERIFY_EMAIL // SUCCESS',
    CLEAR_ERROR_MESSAGE: '[user] CLEAR_ERROR_MESSAGE',

    SET_PENDING_CONTRACT: '[user] SET_PENDING_CONTRACT',

    SET_ACCOUNT_CONNECTION_STATUS: '[user] SET_ACCOUNT_CONNECTION_STATUS',
    SET_IS_ON_PAYMENT_PLAN: '[user] SET_IS_ON_PAYMENT_PLAN',
    SET_VARIABLE_RATE: '[user] SET_VARIABLE_RATE',

    SET_USER_PROFILE: '[user] SET_USER_PROFILE',
}

export const userActions = {
    loadUser: createAction<RequestConfig>(userActionTypes.LOAD_USER),
    loadUserSuccess: createAction<UserProfile>(userActionTypes.LOAD_USER_SUCCESS),
    loadUserFail: createAction<string>(userActionTypes.LOAD_USER_FAIL),

    reloadUser: createAction<UserProfileAccount>(userActionTypes.RELOAD_USER),

    logUserInSuccess: createAction<LoginSuccessModel>(userActionTypes.LOGIN_SUCCESS),

    logUserOut: createAction(userActionTypes.LOGOUT),
    logUserOutSuccess: createAction(userActionTypes.LOGOUT_SUCCESS),

    requestVerification: createAction(userActionTypes.REQUEST_VERIFICATION),
    verifyEmailSuccess: createAction(userActionTypes.VERIFY_EMAIL_SUCCESS),
    clearErrorMessage: createAction(userActionTypes.CLEAR_ERROR_MESSAGE),

    setHasPendingContract: createAction<ReadonlyArray<number | undefined>>(userActionTypes.SET_PENDING_CONTRACT),
    setAccountConnectionStatus: createAction(userActionTypes.SET_ACCOUNT_CONNECTION_STATUS),
    setIsOnPaymentPlan: createAction(userActionTypes.SET_IS_ON_PAYMENT_PLAN),
    setVariableRate: createAction(userActionTypes.SET_VARIABLE_RATE),

    setUserProfile: createAction<Partial<UserProfile>>(userActionTypes.SET_USER_PROFILE),
}

const initialState = {
    isVerificationRequested: false,
}

export const userReducer = reducer<UserState>(initialState)
    .add<Partial<UserProfile>>(userActionTypes.SET_USER_PROFILE, (state, userProfile) => ({
        ...state,
        profile: state.profile && {
            ...state.profile,
            hasScanaHW: true,
            accounts: userProfile.accounts ? [...state.profile.accounts, userProfile.accounts[0]] : state.profile.accounts,
            serviceAddresses: userProfile.serviceAddresses
                ? [...state.profile.serviceAddresses, userProfile.serviceAddresses[0]]
                : state.profile.serviceAddresses,
        },
    }))
    .add<UserProfile>(userActionTypes.LOAD_USER_SUCCESS, (state, userProfile) => ({
        ...state,
        profile: {
            ...userProfile,
            accounts: userProfile.accounts.map(a => ({
                ...a,
                customerPhoneNumber: formatPhoneNumberForStorage(a.customerPhoneNumber),
            })),
        },
    }))
    .add(userActionTypes.REQUEST_VERIFICATION, state => ({
        ...state,
        isVerificationRequested: true,
    }))
    .add(userActionTypes.VERIFY_EMAIL_SUCCESS, state => ({
        ...state,
        profile: state.profile && {
            ...state.profile,
            isEmailVerified: true,
        },
    }))
    .add(userActionTypes.LOAD_USER_FAIL, (state, error) => ({
        ...state,
        error,
    }))
    .add(userActionTypes.CLEAR_ERROR_MESSAGE, state => ({
        ...state,
        error: undefined,
    }))
    .add(userActionTypes.SET_ACCOUNT_CONNECTION_STATUS, (state, { accountId, accountConnectionStatus }) => ({
        ...state,
        profile: state.profile && {
            ...state.profile,
            accounts: state.profile.accounts.map(a => ({
                ...a,
                accountConnectionStatus: a.accountId === accountId ? accountConnectionStatus : a.accountConnectionStatus,
            })),
        },
    }))
    .add(userActionTypes.SET_IS_ON_PAYMENT_PLAN, (state, { billingAccountNumber, isOnPaymentPlan }) => ({
        ...state,
        profile: state.profile && {
            ...state.profile,
            billingAccounts: state.profile.billingAccounts.map(ba => ({
                ...ba,
                isOnPaymentPlan: ba.billingAccountNumber === billingAccountNumber ? isOnPaymentPlan : ba.isOnPaymentPlan,
            })),
        },
    }))
    .add(userActionTypes.SET_VARIABLE_RATE, (state, { accountId, variableRate }) => ({
        ...state,
        profile: state.profile && {
            ...state.profile,
            accounts: state.profile.accounts.map(a => ({
                ...a,
                rate: a.accountId === accountId ? variableRate : a.rate,
            })),
        },
    }))
    .add<ReadonlyArray<number>>(userActionTypes.SET_PENDING_CONTRACT, (state, accountIds) => ({
        ...state,
        profile: state.profile && {
            ...state.profile,
            accounts: state.profile.accounts.map(a => ({
                ...a,
                hasPendingContract: accountIds.indexOf(a.accountId) > -1 ? true : a.hasPendingContract,
            })),
        },
    }))
    .build()

const sagas = {
    *reloadUser(action: { readonly payload: UserProfileAccount }) {
        const userProfile: UserProfile | null = yield call(() => customerInfoApiClient.getCurrentUserProfile({ showGlobalLoader: false }))
        if (userProfile) {
            if (userProfile.accounts.find(a => a.accountId === action.payload.accountId)) {
                yield put(userActions.loadUserSuccess(userProfile))
            }
        }
    },
    *loginSuccess(action: { readonly payload: LoginSuccessModel }) {
        const { onLoginComplete } = action.payload
        yield put(userActions.loadUser())
        yield take(userActionTypes.LOAD_USER_SUCCESS)

        const userProfile = (yield select(UserSelectors.selectProfile)) as UserProfile | undefined

        if (userProfile) {
            yield put(
                notificationActions.showNotification({
                    notificationId: 'login',
                    message: `Logged in as ${userProfile.email} `,
                }),
            )
        }

        if (onLoginComplete) {
            yield call(() => onLoginComplete(userProfile))
        }
    },

    *loadUser(action: { readonly payload?: RequestConfig }) {
        yield put(userActions.clearErrorMessage())
        const requestConfig = action.payload

        try {
            const userProfile: UserProfile | null = yield call(() => customerInfoApiClient.getCurrentUserProfile(requestConfig))
            if (userProfile) {
                identifyUserForFullStory(userProfile.userProfileId.toString(), mapToFullStoryIdentity(userProfile))
                yield put(userActions.loadUserSuccess(userProfile))
            }
        } catch (e) {
            const errorMessage = 'There was a problem getting the user profile: ' + e.message
            apiClient.clearToken()
            yield put(userActions.loadUserFail(errorMessage))
        }
    },

    *handleLogout() {
        yield apiClient.logout({ showGlobalLoader: false })
        yield put(userActions.logUserOutSuccess())
        yield put(enrollmentModelActions.clearAfterSubmit())
        yield call(() => {
            clearFromStorage(CHECKOUT_STORAGE_KEY)
            clearFromStorage(HW_CHECKOUT_STORAGE_KEY)
        })
        yield put(notificationActions.showNotification({ notificationId: 'logout', message: 'Logged out' }))
    },
}

export function* userSaga() {
    yield all([
        takeLatest(userActionTypes.LOGIN_SUCCESS, sagas.loginSuccess as any),
        takeLatest(userActionTypes.LOAD_USER, sagas.loadUser as any),
        takeLatest(userActionTypes.LOGOUT, sagas.handleLogout),
        takeLatest(userActionTypes.RELOAD_USER, sagas.reloadUser as any),
    ])
}

export class UserSelectors {
    public static readonly selectProfile = (state: CommonReduxState) => state.user.profile

    public static readonly selectLoggedIn = (state: CommonReduxState): boolean => !!state.user.profile

    public static readonly selectUserServiceAddresses = (state: CommonReduxState): ReadonlyArray<UserProfileServiceAddress> => {
        const profile = UserSelectors.selectProfile(state)

        return profile?.serviceAddresses || []
    }

    public static readonly selectAccounts = (state: CommonReduxState): ReadonlyArray<Account> => {
        const partyAccounts = UserSelectors.selectPartyAccounts(state)
        const subscriptionAccounts = UserSelectors.selectSubscriptionAccounts(state)
        return [...partyAccounts, ...subscriptionAccounts]
    }

    public static readonly selectAccountsByAddressKey = (state: CommonReduxState, addressKey: string | undefined): ReadonlyArray<Account> => {
        const accounts = UserSelectors.selectAccounts(state)

        return accounts.filter(account => account.serviceAddressKey === addressKey) || []
    }
    public static readonly selectSubscriptionAccounts = (state: CommonReduxState): ReadonlyArray<CommunitySolarAccount> => {
        const profile = UserSelectors.selectProfile(state)

        return profile?.communitySolarSubscriptions || []
    }

    public static readonly selectSubscriptionAccountsByAddressKey = (
        state: CommonReduxState,
        addressKey: string | undefined,
    ): ReadonlyArray<CommunitySolarAccount> => {
        const accounts = UserSelectors.selectSubscriptionAccounts(state)

        return accounts.filter(account => account.serviceAddressKey === addressKey) || []
    }

    public static readonly selectPartyAccounts = (state: CommonReduxState): ReadonlyArray<UserProfileAccount> => {
        const profile = UserSelectors.selectProfile(state)

        return profile?.accounts || []
    }

    public static readonly selectPartyAccountsByAddressKey = (state: CommonReduxState, addressKey: string | undefined): ReadonlyArray<UserProfileAccount> => {
        const accounts = UserSelectors.selectPartyAccounts(state)

        return accounts.filter(account => account.serviceAddressKey === addressKey) || []
    }

    public static readonly selectEmail = (state: CommonReduxState): string | undefined => {
        const profile = UserSelectors.selectProfile(state)
        return profile && profile.email
    }

    public static readonly selectIsEmailVerified = (state: CommonReduxState): boolean => {
        const profile = UserSelectors.selectProfile(state)
        return !!profile && profile.isEmailVerified
    }

    public static readonly selectIsVerificationRequested = (state: CommonReduxState): boolean => state.user.isVerificationRequested

    public static readonly selectIsPaperless = (state: CommonReduxState): boolean => {
        const profile = UserSelectors.selectProfile(state)
        return !!profile && profile.isPaperless
    }

    public static readonly selectUserServiceAddressesByZip = (state: CommonReduxState, zipCode: string) => {
        const serviceAddresses = UserSelectors.selectUserServiceAddresses(state)
        return serviceAddresses.filter(address => address.zipCode === zipCode)
    }

    public static readonly selectHasDirectBillAccount = (state: CommonReduxState) => {
        const accounts = UserSelectors.selectPartyAccounts(state)

        return accounts.some(a => a.isDirectBilledUtilityAccount)
    }

    public static readonly selectIsCommercialIndustrial = (state: CommonReduxState) => {
        const profile = UserSelectors.selectProfile(state)
        return profile && profile.isCommercialIndustrial
    }

    public static readonly selectUserServiceAddress = (state: CommonReduxState, addressKey = '') => {
        return UserSelectors.selectUserServiceAddresses(state).find(sa => sa.serviceAddressKey === addressKey)
    }
    public static readonly selectUserServiceAddressByHash = (state: CommonReduxState, addressHash: string) => {
        return UserSelectors.selectUserServiceAddresses(state).find(sa => sa.addressHash === addressHash)
    }

    public static readonly selectAccountByIdentifier = (state: CommonReduxState, accountIdentifier: string) => {
        //-- Even though both accountIdentifiers below are strings, the one being passed in is being treated like a number
        const selectedAccount = UserSelectors.selectAccounts(state).find(a => a.accountIdentifier === accountIdentifier.toString())
        return selectedAccount
    }

    public static readonly selectHasAddressInState = (state: CommonReduxState, stateCode: string) => {
        const serviceAddresses = UserSelectors.selectUserServiceAddresses(state).filter(address => address.state === stateCode)
        return serviceAddresses.length > 0
    }

    public static readonly selectCurrentAccount = (state: CommonReduxState, addressKey: string | undefined, lobCode: LineOfBusinessCode) => {
        const existingServiceAddress = UserSelectors.selectUserServiceAddress(state, addressKey)
        const accounts = UserSelectors.selectPartyAccounts(state)
        if (existingServiceAddress) {
            const sortedActiveAccounts = accounts
                .filter(a => a.serviceAddressKey === existingServiceAddress.serviceAddressKey)
                .filter(a => a.lineOfBusinessCode === lobCode)
                .filter(a => [AccountStatus.active, AccountStatus.pending].includes(a.status))
                .sort(a => (a.status === AccountStatus.pending ? -1 : 1))
            return sortedActiveAccounts[0]
        }
    }
    public static readonly selectError = (state: CommonReduxState) => state.user.error
}

export interface UserState {
    readonly profile?: UserProfile
    readonly isVerificationRequested: boolean
    readonly error?: string | unknown
}

export interface LoginSuccessModel {
    readonly onLoginComplete?: (user?: UserProfile) => void
}
