import * as React from 'react'

import { LegacyUsage } from '@igs-web/common-models/models/legacy-data'
import { Usage } from '@igs-web/common-models/models/usage'

import { UsageTable } from './usage-table'

export const UsageGrid = ({ usages, legacyUsages, hideCharges, accountId }: Props): JSX.Element => {
    return (
        <div>
            <UsageTable
                dataTestId="usage-and-rates-history-table"
                usages={usages}
                legacyUsages={legacyUsages}
                hideCharges={hideCharges}
                accountId={accountId}
            />
        </div>
    )
}

interface Props {
    readonly usages: ReadonlyArray<Usage>
    readonly legacyUsages?: ReadonlyArray<LegacyUsage>
    readonly accountId: number
    readonly hideCharges: boolean
}
