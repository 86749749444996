import { FeatureFlag } from 'root/constants'

import { sitefinityApiClient } from '../api/sitefinity-api-client'

export const isFeatureOn = async (feature: FeatureFlag) => {
    const date = new Date()
    const featureflags = (await sitefinityApiClient.getFeatureFlags({ showGlobalLoader: false })) || []
    const flag = featureflags.find(r => r.featureType === feature)

    return !!flag && new Date(flag.goLiveDate) <= date
}
