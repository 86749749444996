import * as Routes from 'domain/routes'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { UserSelectors } from '@igs-web/common-components/domain/user/user-redux'

export const ProtectedRoute = ({ children, ...rest }: RouteProps) => {
    const isAuthenticated = useSelector(UserSelectors.selectLoggedIn)

    const returnTo = rest.location?.pathname
    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated ? (
                    <>{children}</>
                ) : (
                    <Redirect
                        to={{
                            pathname: Routes.LoginRoute,
                            state: returnTo,
                        }}
                    />
                )
            }
        />
    )
}
