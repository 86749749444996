import * as React from 'react'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { FaCheck } from 'react-icons/fa'

import styled from 'styled-components'

import { SvgSize } from '@igs-web/common-ui-components/_atoms/SVGs/svg-icon'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { error, headerColor, lightDark } from '@igs-web/common-ui-components/styles/theme'

const InfoBox = styled.div<Props>`
    display: grid;
    width: 100%;
    grid-template-columns: min-content 1fr;
    grid-column-gap: ${Spacing.Medium};
    padding: ${Spacing.Medium};
    align-items: center;
    svg {
        font-size: ${SvgSize.Medium};
    }
    border: 1px solid ${p => (p.infoTextType === InfoTextType.Error ? error : lightDark)};
    color: ${p => (p.infoTextType === InfoTextType.Error ? error : 'inherit')};
    > div > p:last-child {
        margin-bottom: 0;
    }
`
export const InfoIcon = styled(AiOutlineInfoCircle)`
    color: ${headerColor};
`
export const CheckIcon = styled(FaCheck)`
    color: ${headerColor};
`
export const ErrorIcon = styled(AiOutlineInfoCircle)`
    color: ${error};
`

export const InfoText = ({ infoTextType = InfoTextType.Informational, children, className, hideIcon = false }: Props) => (
    <InfoBox className={className} infoTextType={infoTextType}>
        {infoTextType === InfoTextType.Informational && !hideIcon && <InfoIcon />}
        {infoTextType === InfoTextType.Success && !hideIcon && <CheckIcon />}
        {infoTextType === InfoTextType.Error && !hideIcon && <ErrorIcon />}
        {hideIcon && <span />}
        <div>{children}</div>
    </InfoBox>
)
interface Props {
    readonly infoTextType?: InfoTextType
    readonly children?: React.ReactNode
    readonly className?: string
    readonly hideIcon?: boolean
}

export enum InfoTextType {
    Informational = 'informational',
    Success = 'success',
    Error = 'error',
}
