import * as React from 'react'
import { useSelector } from 'react-redux'

import { OfferSelectors } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { PremiseResponse } from '@igs-web/common-models/models/premise-response'
import { getEventModel, pushStartedCheckoutEvent } from '@igs-web/common-utilities/services/google-analytics-services'

import { ResultsStep } from './results-step'
import { AddressSearchStep } from './search-step'

export const enum ServiceAddressSearchSteps {
    search = 'search',
    results = 'results',
}

export const ServiceAddressSearchStep = ({ setShowAssistanceFooter, checkIsSelectedOfferExpired }: ServiceAddressSearchStepProps) => {
    const [currentStep, setCurrentStep] = React.useState<string>(ServiceAddressSearchSteps.search)
    const selectedOffers = useSelector(OfferSelectors.selectSelectedOffers)
    const [searchResults, setSearchResults] = React.useState<ReadonlyArray<PremiseResponse>>([])

    React.useEffect(() => {
        window.scrollTo(0, 0)
        checkIsSelectedOfferExpired()
        pushStartedCheckoutEvent(getEventModel(selectedOffers))
    }, [])

    React.useEffect(() => {
        setShowAssistanceFooter(currentStep === ServiceAddressSearchSteps.search)
    }, [currentStep])

    return (
        <>
            {currentStep === ServiceAddressSearchSteps.search && <AddressSearchStep setCurrentStep={setCurrentStep} setSearchResultsState={setSearchResults} />}
            {currentStep === ServiceAddressSearchSteps.results && <ResultsStep searchResults={searchResults} setCurrentStep={setCurrentStep} />}
        </>
    )
}

interface ServiceAddressSearchStepProps {
    readonly setShowAssistanceFooter: React.Dispatch<React.SetStateAction<boolean>>
    readonly checkIsSelectedOfferExpired: () => void
}
