import * as React from 'react'
import { Link, LinkProps as RouterLinkProps } from 'react-router-dom'

import { ButtonBaseProps, ButtonStyleType, mapToStyle } from '@igs-web/common-ui-components/_atoms/buttons/button'

import { LinkBase, LinkProps } from './link'

export const LinkButton = ({ className, buttonStyle, ...htmlProps }: LinkButtonProps): JSX.Element => {
    const buttonStyleClass = mapToStyle(buttonStyle || ButtonStyleType.Primary)
    return <LinkBase className={`btn ${buttonStyleClass} ${className || 'f'}`} {...htmlProps} />
}

export const RouterLinkButton = ({className, buttonStyle, ...htmlProps}: RouterLinkButtonProps): JSX.Element => {
    const buttonStyleClass = mapToStyle(buttonStyle || ButtonStyleType.Primary)
    return(<Link className={`btn ${buttonStyleClass} ${className || 'f'}`} {...htmlProps}/>)
}

type RouterLinkButtonProps = LinkButtonProps & RouterLinkProps

export interface LinkButtonProps extends LinkProps, ButtonBaseProps {
    readonly buttonStyle?: ButtonStyleType
}
