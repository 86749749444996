import _difference from 'lodash-es/difference'
import _uniq from 'lodash-es/uniq'
import { all, put, select, takeLatest } from 'redux-saga/effects'
import { defaultCampaign } from 'root/constants'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { OfferAccountType } from '@igs-web/common-models/constants/account-type'
import { AccountStatus } from '@igs-web/common-models/models/account-model'
import { Address } from '@igs-web/common-models/models/address'
import { CommunitySolarAccount } from '@igs-web/common-models/models/community-solar-model'
import { LineOfBusinessCode, PartyLinesOfBusiness } from '@igs-web/common-models/models/line-of-business'
import { OfferModel } from '@igs-web/common-models/models/offer-model'
import { UserProfileAccount, UserProfileServiceAddress } from '@igs-web/common-models/models/user-profile-model'
import { getOffers } from '@igs-web/common-utilities/services/offer-retrieval'
import { nonUnique } from '@igs-web/common-utilities/utilities/array-utilities'
import { fireCustomFullStoryEvent, FsEvent } from '@igs-web/common-utilities/utilities/fs-logger'
import { createAction, reducer } from '@igs-web/common-utilities/utilities/reducer-utilities'

import { enrollmentModelActions } from './enrollment/enrollment-redux-actions'
import { UserSelectors } from './user/user-redux'

export const MyAccountActionTypes = {
    SELECT_SERVICE_ADDRESS: '[my-account] SELECT_SERVICE_ADDRESS',
    ONLY_SELECT_SERVICE_ADDRESS: '[my-account] ONLY_SELECT_SERVICE_ADDRESS',
    SELECT_UTILITY_ACCOUNT: '[my-account] SELECT_UTILITY_ACCOUNT',
    TOGGLE_SELECTED_ACCOUNT: '[my-account] TOGGLE_SELECTED_ACCOUNT',
    SET_PRODUCT_AVAILABILITY: '[my-account] SET_PRODUCT_AVAILABILITY',
}

//TODO: Think about how to get rid of onlySelectServiceAccounts. It exists because there's a saga
// which loads offers when you select a service account, only to determine what LOBs of service are
// offered, which IIRC is only for showing LOB card leads on igs. Should we put our company name into
// redux and use the company features object instead? It's more than I want to do today with this epic-BF

export const myAccountActions = {
    selectServiceAddress: createAction<string>(MyAccountActionTypes.SELECT_SERVICE_ADDRESS),
    onlySelectServiceAddress: createAction<string>(MyAccountActionTypes.ONLY_SELECT_SERVICE_ADDRESS),
    selectUtilityAccount: createAction<number>(MyAccountActionTypes.SELECT_UTILITY_ACCOUNT),
    toggleSelectedAccount: createAction<number | string>(MyAccountActionTypes.TOGGLE_SELECTED_ACCOUNT),
    setProductAvailability: createAction<UpdateProductAvailabilityRequest>(MyAccountActionTypes.SET_PRODUCT_AVAILABILITY),
}

const selectServiceAddress = (state: Readonly<MyAccountState>, addressKey: string): MyAccountState => ({
    ...state,
    selectedServiceAddressKey: addressKey,
    selectedAccountIdentifier: state.selectedServiceAddressKey === addressKey ? state.selectedAccountIdentifier : undefined,
})

export const myAccountReducer = reducer<MyAccountState>({ productAvailability: {} })
    .add<string>(MyAccountActionTypes.SELECT_SERVICE_ADDRESS, selectServiceAddress)
    .add<string>(MyAccountActionTypes.ONLY_SELECT_SERVICE_ADDRESS, selectServiceAddress)
    .add<number | undefined>(MyAccountActionTypes.SELECT_UTILITY_ACCOUNT, (state, accountId) => ({
        ...state,
        selectedAccountId: accountId,
    }))
    .add<string | undefined>(MyAccountActionTypes.TOGGLE_SELECTED_ACCOUNT, (state, accountIdentifier) => ({
        ...state,
        selectedAccountIdentifier: state.selectedAccountIdentifier !== accountIdentifier ? accountIdentifier : undefined,
    }))
    .add<UpdateProductAvailabilityRequest>(MyAccountActionTypes.SET_PRODUCT_AVAILABILITY, (state, request) => ({
        ...state,
        productAvailability: {
            ...state.productAvailability,
            [request.zipCode]: request.lineOfBusinessCodes,
        },
    }))
    .build()

const sagas = {
    *selectAddress(action: { readonly payload: string }) {
        const addressKey = action.payload
        const userAddress: UserProfileServiceAddress | undefined = yield select(UserSelectors.selectUserServiceAddress, addressKey)
        const accounts: ReadonlyArray<UserProfileAccount> = yield select(UserSelectors.selectPartyAccountsByAddressKey, addressKey)
        if (userAddress) {
            yield put(enrollmentModelActions.updateServiceAddress({ address: userAddress }))
            const accountType = accounts.some(a => a.accountType === OfferAccountType.Commercial) ? OfferAccountType.Commercial : OfferAccountType.Residential
            try {
                const offers: ReadonlyArray<OfferModel> = yield getOffers(userAddress.zipCode, accountType, defaultCampaign)
                yield put(
                    myAccountActions.setProductAvailability({
                        zipCode: userAddress.zipCode,
                        lineOfBusinessCodes: _uniq(offers.map(o => o.primaryProduct.lineOfBusinessCode)),
                    }),
                )
            } catch (e) {
                fireCustomFullStoryEvent(FsEvent.genericError, { eventName: e.message, e })
            }
        }
    },
}

export function* myAccountSagas() {
    yield all([takeLatest(MyAccountActionTypes.SELECT_SERVICE_ADDRESS, sagas.selectAddress as any)])
}

export class MyAccountSelectors {
    public static readonly selectSelectedServiceAddress = (state: CommonReduxState) =>
        state.myaccount.selectedServiceAddressKey ? UserSelectors.selectUserServiceAddress(state, state.myaccount.selectedServiceAddressKey) : undefined

    public static readonly selectSelectedAccount = (state: CommonReduxState) =>
        state.myaccount.selectedAccountIdentifier ? UserSelectors.selectAccountByIdentifier(state, state.myaccount.selectedAccountIdentifier) : undefined

    public static readonly selectSelectedPartyAccount = (state: CommonReduxState) => {
        const selectedAccount = MyAccountSelectors.selectSelectedAccount(state)

        if (selectedAccount && PartyLinesOfBusiness.includes(selectedAccount?.lineOfBusinessCode)) {
            return selectedAccount as UserProfileAccount
        }
        return undefined
    }

    public static readonly selectSelectedAccountId = (state: CommonReduxState) => state.myaccount.selectedAccountIdentifier

    public static readonly selectPartyServiceAddressAccounts = (state: CommonReduxState): ReadonlyArray<UserProfileAccount> => {
        const serviceAddress = MyAccountSelectors.selectSelectedServiceAddress(state)
        if (!serviceAddress) {
            return []
        }
        return UserSelectors.selectPartyAccountsByAddressKey(state, serviceAddress.serviceAddressKey)
    }

    public static readonly selectSubscriptionServiceAddressAccounts = (state: CommonReduxState): ReadonlyArray<CommunitySolarAccount> => {
        const serviceAddress = MyAccountSelectors.selectSelectedServiceAddress(state)
        if (!serviceAddress) {
            return []
        }
        return UserSelectors.selectSubscriptionAccountsByAddressKey(state, serviceAddress.serviceAddressKey)
    }

    public static readonly selectActiveAndPendingServiceAddressAccounts = (state: CommonReduxState): ReadonlyArray<UserProfileAccount> => {
        const accounts = MyAccountSelectors.selectPartyServiceAddressAccounts(state)
        const inactiveDuplicates = MyAccountSelectors.selectDuplicateInactiveAccounts(state)
        return _difference(accounts, inactiveDuplicates)
    }

    public static readonly selectDuplicateInactiveAccounts = (state: CommonReduxState): ReadonlyArray<UserProfileAccount> => {
        const accounts = MyAccountSelectors.selectPartyServiceAddressAccounts(state)
        const accountNumbers = accounts.map(account => account.accountNumber)
        const duplicateAccountNumbers = nonUnique(accountNumbers)
        return accounts
            .filter(account => account.status === AccountStatus.inactive)
            .filter(account => account.isDirectBilledUtilityAccount)
            .filter(account => account.accountNumber && duplicateAccountNumbers.includes(account.accountNumber))
    }

    public static readonly selectHasActiveAccountByLob = (state: CommonReduxState, lobCode: LineOfBusinessCode): boolean => {
        const accounts = MyAccountSelectors.selectActiveAndPendingServiceAddressAccounts(state)
        return accounts.some(a => a.lineOfBusinessCode === lobCode)
    }

    public static readonly selectLobIsAvailable = (state: CommonReduxState, lineOfBusinessCode: LineOfBusinessCode): boolean => {
        const address: Address | undefined = MyAccountSelectors.selectSelectedServiceAddress(state)
        const availableLobs = (address && address.zipCode && state.myaccount.productAvailability[address.zipCode]) || []
        return availableLobs.includes(lineOfBusinessCode)
    }
}

export interface MyAccountState {
    readonly selectedServiceAddressKey?: string
    readonly selectedAccountIdentifier?: string
    readonly productAvailability: {
        readonly [zipCode: string]: ReadonlyArray<LineOfBusinessCode>
    }
}

interface UpdateProductAvailabilityRequest {
    readonly zipCode: string
    readonly lineOfBusinessCodes: ReadonlyArray<LineOfBusinessCode>
}
