import * as React from 'react'
import { useSelector } from 'react-redux'

import { wideBanner } from '@igs-web/common-components/_molecules/full-width-banner'
import { CheckoutProvider } from '@igs-web/common-components/domain/checkout/hooks/use-checkout'
import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { OfferSelectors } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { ErrorBoundary } from '@igs-web/common-components/domain/errors/error-boundary'
import { UserSelectors } from '@igs-web/common-components/domain/user/user-redux'
import { EnrollmentModel } from '@igs-web/common-models/models/enrollment-model'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { HW_CHECKOUT_STORAGE_KEY } from '@igs-web/common-utilities/constants/constants'
import { getEventModel, pushStartedCheckoutEvent } from '@igs-web/common-utilities/services/google-analytics-services'
import { getFromStorage } from '@igs-web/common-utilities/utilities/storage-utilities'

import { NavigationBanner } from 'molecules/header/navigation-banner'

import { CheckoutGrid } from 'domain/checkout/shared/checkout-components'

import { useOffersQueryStringHandler } from '../hooks/use-offers-query-string-handler'

import { HwCheckoutExperienceStandard } from './hw-checkout-experience-standard'
import { HwCheckoutExperienceUserAddress } from './hw-checkout-experience-user-address'
import { HwCheckoutExperienceUserZip } from './hw-checkout-experience-user-zip'
import { HWOfferLoader } from './hw-offer-loader'

export const HwCheckoutPage = () => {
    const company = useCompany()
    const storedEnrollmentModel = getFromStorage<EnrollmentModel>(HW_CHECKOUT_STORAGE_KEY, false)
    const { getHwOffersQueryStringParameters } = useOffersQueryStringHandler()
    const selectedOffers = useSelector(OfferSelectors.selectSelectedOffers)

    const userProfile = useSelector(UserSelectors.selectProfile)
    const { addressKey } = getHwOffersQueryStringParameters()

    React.useEffect(() => {
        pushStartedCheckoutEvent(getEventModel(selectedOffers))
    }, [])

    return (
        <ErrorBoundary company={company}>
            <CheckoutGrid $maxWidth={Breakpoint.LargeDesktop}>
                <CheckoutProvider enrollmentState={storedEnrollmentModel} storageKey={HW_CHECKOUT_STORAGE_KEY}>
                    <NavigationBanner title="Sign Up For Home Warranty" style={wideBanner} showNav={false} />
                    <HWOfferLoader />
                    {!userProfile && <HwCheckoutExperienceStandard />}
                    {userProfile && !addressKey && <HwCheckoutExperienceUserZip userProfile={userProfile} />}
                    {userProfile && addressKey && <HwCheckoutExperienceUserAddress userProfile={userProfile} addressKey={addressKey} />}
                </CheckoutProvider>
            </CheckoutGrid>
        </ErrorBoundary>
    )
}

export enum HwEnrollmentType {
    standard = 'standard',
    userAddress = 'userAddress',
    userZip = 'userZip',
}
